import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';

// assets

// services

// props
import { IEmployeeContractJobdeskResourceShortProps } from '../../../../../../props/employees/contracts/jobdesk';

// components
import { DefaultButton, IconButton, Panel, PanelType, PrimaryButton, Stack, TextField } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import Tag from '../../../../../uiframeworks/tag';
import GeneralService from '../../../../../../services/general';

type EmployeeContractJobdeskFormProps = {
    value: IEmployeeContractJobdeskResourceShortProps[];
    onChange(jobdesks: IEmployeeContractJobdeskResourceShortProps[]): void;
}

const EmployeeContractJobdeskForm: React.FC<EmployeeContractJobdeskFormProps> = (props: EmployeeContractJobdeskFormProps) => {
    const [jobdesks, setJobdesks] = React.useState<IEmployeeContractJobdeskResourceShortProps[]>(props.value);
    const [surfaceMode, setSurfaceMode] = React.useState<string | undefined>();
    const [activeJobdesk, setActiveJobdesk] = React.useState<IEmployeeContractJobdeskResourceShortProps | undefined>();

    useEffect(() => {
        setJobdesks(props.value);
    }, [props.value]);

    return <Stack tokens={{ childrenGap: 10 }}>
        <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign={'space-between'} verticalAlign={'center'}>
            <Label required={true} size={'small'}>Jobdesks</Label>
            <PrimaryButton text={'Add jobdesk'} iconProps={{ iconName: "Add" }} onClick={() => {
                setActiveJobdesk({
                    id: GeneralService.guid(),
                    name: "",
                    description: ""
                })
            }} />
        </Stack>
        {jobdesks.length < 1 ? <Text size={'small'} className='color-red'>This field is required</Text> : null}
        {jobdesks.length > 0 ? <Stack tokens={{ childrenGap: 10 }}>
            {jobdesks.map((jobdesk) => {
                return <>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Stack grow={1}>
                            <Tag>
                                <Stack tokens={{ childrenGap: 5 }}>
                                    <Label size={'xsmall'}>{jobdesk.name.toUpperCase()}</Label>
                                    {jobdesk.description ? <Text style={{ whiteSpace: 'break-spaces' }} size={'xsmall'}>{jobdesk.description}</Text> : null}
                                </Stack>
                            </Tag>
                        </Stack>
                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                            <IconButton iconProps={{ iconName: "Edit" }} onClick={() => {
                                setActiveJobdesk(jobdesk);
                                setSurfaceMode('update');
                            }} />
                            <IconButton iconProps={{ iconName: "Delete" }} />
                        </Stack>
                    </Stack>
                    <Stack className='divider'></Stack>
                </>
            })}
        </Stack> : null}
        <Panel headerText={activeJobdesk ? "Add Jobdesk" : "Update Jobdesk"}
            isOpen={activeJobdesk !== undefined}
            type={PanelType.medium}
            onDismiss={() => {
                setSurfaceMode(undefined);
                setActiveJobdesk(undefined);
            }}
            isFooterAtBottom={true}
            onRenderFooterContent={() => {
                return <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <PrimaryButton text={surfaceMode === 'add' ? "Add" : "Update"}
                        disabled={(activeJobdesk?.name || "") === "" || (activeJobdesk?.name || "").length > 100 || (activeJobdesk?.description || "").length > 1000}
                        onClick={() => {
                            if (activeJobdesk) {
                                const _jobdesks = jobdesks;
                                const idx = _jobdesks.findIndex((j) => j.id === activeJobdesk.id);

                                if (idx > -1) {
                                    _jobdesks[idx] = activeJobdesk;
                                } else {
                                    _jobdesks.push(activeJobdesk);
                                }

                                setJobdesks(_jobdesks);
                                setActiveJobdesk(undefined);
                            }
                        }} />
                    <DefaultButton text={"Cancel"} onClick={() => {
                        setSurfaceMode(undefined);
                        setActiveJobdesk(undefined);
                    }} />
                </Stack>
            }}>
            {activeJobdesk ? <>
                <Stack tokens={{ childrenGap: 20 }}>
                    <TextField label={"Name"}
                        required
                        onChange={(evt, value) => {
                            if (activeJobdesk) {
                                setActiveJobdesk({ ...activeJobdesk, name: value || "" });
                            }
                        }}
                        value={activeJobdesk.name} />
                    <TextField label={"Description"}
                        multiline
                        rows={5}
                        autoAdjustHeight
                        resizable={false}
                        onChange={(evt, value) => {
                            if (activeJobdesk) {
                                setActiveJobdesk({ ...activeJobdesk, description: value || "" });
                            }
                        }}
                        value={activeJobdesk.description || ""} />
                </Stack>
            </> : null}
        </Panel>
    </Stack>
};

export default EmployeeContractJobdeskForm;
