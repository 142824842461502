import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBox, faBuilding, faChartBar, faChartLineUp, faChartSimple, faChartUser, faFileInvoiceDollar, faFileShield, faFileSignature, faGear, faGridHorizontal, faHandHoldingDollar, faHome, faSackDollar, faScreenUsers, faShip, faTag, faTrain, faTruck, faUser, faUserLock, faUsersRectangle } from "@fortawesome/pro-light-svg-icons";

export type SidebarMenuProps = {
    groups: MenuGroupProps[];
}

export type MenuGroupProps = {
    key: string;
    label?: string;
    icon?: IconProp;
    expanded?: boolean;
    collapsible?: boolean;
    items: MenuItemProps[];
}

export type MenuItemProps = {
    key: string;
    text: string;
    icon: IconProp;
    href?: string;
    active?: boolean;
    permissions?: string[];
}

const SidebarMenu: SidebarMenuProps = {
    groups: [
        {
            key: 'main',
            expanded: true,
            items: [
                {
                    key: 'home',
                    text: 'Home',
                    icon: faHome,
                    href: '/'
                },
                {
                    key: 'myPerformance',
                    text: 'My Performance',
                    icon: faChartUser,
                    href: '/users/administrators/my/performance',
                    permissions: []
                },
            ]
        },
        {
            key: 'users',
            label: 'USERS',
            collapsible: true,
            expanded: true,
            items: [
                {
                    key: 'companies',
                    text: 'Companies',
                    icon: faBuilding,
                    href: '/users/companies',
                    permissions: ['companies.read.all']
                },
                {
                    key: 'customers',
                    text: 'Customers',
                    icon: faUser,
                    href: '/users/customers',
                    permissions: ['customers.read.all']
                },
                {
                    key: 'administrators',
                    text: 'Administrators',
                    icon: faUserLock,
                    href: '/users/administrators',
                    permissions: ['administrators.read.all']
                },
                {
                    key: 'vendors',
                    text: 'Vendors',
                    icon: faUsersRectangle,
                    href: '/operationals/vendors',
                    permissions: ['vendors.read.all']
                }
            ]
        },
        {
            key: 'orders',
            label: 'ORDERS',
            collapsible: true,
            expanded: true,
            items: [
                {
                    key: 'orderSummary',
                    text: 'Order Summary',
                    icon: faChartSimple,
                    href: '/orders/dashboard',
                    permissions: ['orders.read.all', 'orders.read.me']
                },
                {
                    key: 'offers',
                    text: 'Offers List',
                    icon: faTag,
                    href: '/offers/list',
                    permissions: ['offers.read.all', 'offers.read.me']
                },
                {
                    key: 'orders',
                    text: 'Orders List',
                    icon: faFileInvoiceDollar,
                    href: '/orders/list',
                    permissions: ['orders.read.all', 'orders.read.me']
                },
                {
                    key: 'goods',
                    text: 'Goods List',
                    icon: faBox,
                    href: '/goods',
                    permissions: []
                }
            ]
        },
        {
            key: 'finance',
            label: 'FINANCE',
            collapsible: true,
            expanded: true,
            items: [
                {
                    key: 'dashboard',
                    text: 'Dashboard',
                    icon: faChartLineUp,
                    href: '/finance',
                    permissions: []
                },
                {
                    key: 'outcomes',
                    text: 'Outcomes',
                    icon: faSackDollar,
                    href: '/finance/outcomes',
                    permissions: ['outcomes.invoices.read.all']
                },
                {
                    key: 'incomes',
                    text: 'Incomes',
                    icon: faFileInvoiceDollar,
                    href: '/finance/incomes',
                    permissions: ['orders.read.all', 'orders.read.me']
                }
            ]
        },
        {
            key: 'operationals',
            label: 'OPERATIONALS',
            collapsible: true,
            expanded: false,
            items: [
                {
                    key: 'ships',
                    text: 'Ships',
                    icon: faShip,
                    href: '/operationals/ships',
                    permissions: ['ships.read.all']
                },
                {
                    key: 'trains',
                    text: 'Trains',
                    icon: faTrain,
                    href: '/operationals/trains',
                    permissions: ['trains.read.all']
                },
                {
                    key: 'trucks',
                    text: 'Trucks',
                    icon: faTruck,
                    href: '/operationals/trucks',
                    permissions: ['trucks.read.all']
                },
                {
                    key: 'internalDocuments',
                    text: 'Internal Document',
                    icon: faFileShield,
                    href: '/operationals/documents',
                    permissions: []
                },
            ]
        },
        {
            key: 'humarResources',
            label: 'HR',
            collapsible: true,
            expanded: false,
            items: [
                {
                    key: 'dashboard',
                    text: 'Dashboard',
                    icon: faGridHorizontal,
                    href: '/hr',
                    permissions: ['employees.contracts.read.all', 'employees.contracts.read.me']
                },
                {
                    key: 'employeeContracts',
                    text: 'Contracts',
                    icon: faFileSignature,
                    href: '/hr',
                    permissions: ['employees.contracts.read.all']
                },
                {
                    key: 'performance',
                    text: 'Performance',
                    icon: faChartUser,
                    href: '/hr/performance',
                    permissions: []
                },
                {
                    key: 'contracts',
                    text: 'Contracts',
                    icon: faFileSignature,
                    href: '/hr/contracts',
                    permissions: []
                },
                {
                    key: 'payroll',
                    text: 'Payroll',
                    icon: faHandHoldingDollar,
                    href: '/hr/payroll',
                    permissions: []
                }
            ]
        }
    ]
};

const SidebarFarMenu: SidebarMenuProps = {
    groups: [
        {
            key: 'settings',
            expanded: true,
            items: [
                {
                    key: 'settings',
                    text: 'Settings',
                    icon: faGear,
                    href: '/settings',
                    permissions: ['roles.create']
                }
            ]
        }
    ]
};

export { SidebarMenu, SidebarFarMenu };