import Request from './../../request';

// import props
import { IDocumentProps, IPaginationResourceShort } from '../../../props/general';
import { IVendorResourceShortProps } from '../../../props/data/vendors';
import { ITruckDocumentResourceShortProps } from '../../../props/data/trucks';

const VendorsService = {
  retrieve: async (
    qs?: string
  ): Promise<{ data: IVendorResourceShortProps[]; pagination: IPaginationResourceShort }> => {
    try {
      const results = await Request.get(`/api/data/vendors?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  resetVerification: async (id: string, data: FormData): Promise<string[] | undefined> => {
    try {
      const results = await Request.post(`/api/data/vendors/${id}/verifications/reset`, data);
      return results;
    } catch (e) {
      throw e;
    }
  },
  inactivate: async (id: string, data: FormData): Promise<string[] | undefined> => {
    try {
      const results = await Request.post(`/api/data/vendors/${id}/inactivate`, data);
      return results;
    } catch (e) {
      throw e;
    }
  },
  activate: async (id: string): Promise<string[] | undefined> => {
    try {
      const results = await Request.post(`/api/data/vendors/${id}/activate`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  verify: async (id: string): Promise<string[] | undefined> => {
    try {
      const results = await Request.post(`/api/data/vendors/${id}/verifications/verify`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  reject: async (id: string, data: FormData): Promise<string[] | undefined> => {
    try {
      const results = await Request.post(`/api/data/vendors/${id}/verifications/reject`, data);
      return results;
    } catch (e) {
      throw e;
    }
  },
  findSimilar: async (qs?: string): Promise<string[] | undefined> => {
    try {
      const results = await Request.get(`/api/data/vendors/find_similar?${qs || ''}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  extractDocument: async (data: FormData): Promise<{
    name?: string;
    taxNumber?: string;
  }> => {
    try {
      const response = await Request.post(`/api/data/vendors/extract_document`, data, 'multipart/form-data');
      let result: {
        name?: string;
        taxNumber: string;
      } = response;

      return result;
    } catch (e) {
      throw (e);
    }
  },
  new: async (data: FormData): Promise<IVendorResourceShortProps> => {
    try {
      const result = await Request.post(`/api/data/vendors`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  update: async (id: string, data: FormData): Promise<IVendorResourceShortProps> => {
    try {
      const result = await Request.post(`/api/data/vendors/${id}?_method=PUT`, data, 'multipart/form-data');
      return result;
    } catch (e) {
      throw e;
    }
  },
  get: async (id: string): Promise<IVendorResourceShortProps> => {
    try {
      const result = await Request.get(`/api/data/vendors/${id}`);
      return result;
    } catch (e) {
      throw e;
    }
  },
  delete: async (id: string): Promise<void> => {
    try {
      await Request.delete(`/api/data/vendors/${id}`);
      return;
    } catch (e) {
      throw e;
    }
  },
  user: {
    retrieve: async (vendorId: string) => {
      try {
        const results = await Request.get(`/api/data/vendors/${vendorId}/users`);
        return results;
      } catch (e) {
        throw e;
      }
    },
    create: async (vendorId: string, userId: string): Promise<void> => {
      try {
        const result = await Request.post(
          `/api/data/vendors/${vendorId}/users/${userId}`,
          new FormData(),
          'multipart/form-data'
        );
        return result;
      } catch (e) {
        throw e;
      }
    },
    delete: async (userId: string): Promise<void> => {
      try {
        const result = await Request.delete(`/api/data/deleteUserVendor/${userId}`);
        return result;
      } catch (e) {
        throw e;
      }
    },
  },
  documents: {
    retrieve: async (id: string): Promise<IDocumentProps[]> => {
      try {
        const results = await Request.get(`/api/data/vendors/${id}/documents`);
        return results;
      } catch (e) {
        throw e;
      }
    },
    new: async (vendorId: string, data: FormData): Promise<IDocumentProps> => {
      try {
        const result = await Request.post(`/api/data/vendors/${vendorId}/documents`, data, 'multipart/form-data');
        return result;
      } catch (e) {
        throw e;
      }
    },
    delete: async (vendorId: string, data: FormData): Promise<void> => {
      try {
        await Request.post(`/api/vendors/${vendorId}/delete_documents`, data);
        return;
      } catch (e) {
        throw e;
      }
    },
  },
};

export default VendorsService;
