import { ActionButton, CommandBar, DefaultButton, DetailsList, Dropdown, Link, MessageBar, MessageBarType, NormalPeoplePicker, PrimaryButton, SelectionMode, SpinnerSize, Stack, TagPicker, TextField } from '@fluentui/react';
import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import moment from 'moment';
import { useStore } from '../../../../../../stores/root';

// assets
import { faArrowRight, faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// props
import { IEmployedContractResourceShort, IEmployedSalaryResourceShort, IUserResourceShortProps } from '../../../../../../props/users/user';
import { IOrderResourceShortProps } from '../../../../../../props/orders/order';

// services
import ErrorService from '../../../../../../services/general/error';
import UserService from '../../../../../../services/users/user';
import GeneralService from '../../../../../../services/general';

// components
import DetailsStack from '../../../../../../components/uiframeworks/detailsStack';
import Text from '../../../../../typography/text';
import Label from '../../../../../typography/label';
import { EmployeeContractService, EmployeeSalaryService } from '../../../../../../services/users/employee';
import LoadingComponent from '../../../../../feedbacks/loading';
import Heading from '../../../../../typography/heading';
import OrderService from '../../../../../../services/orders/order';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IEmployeePerformanceCommissionResourceShortProps, IEmployeePerformanceResourceShortProps } from '../../../../../../props/users/employees/performance';
import EmployeePerformanceService from '../../../../../../services/users/employees/performance';
import DeliveredGoodsType from '../../../../../../manifests/deliveredGoodsType';
import LoadingDialogComponent from '../../../../../feedbacks/loadingDialog';
import OutcomeSalaryService from '../../../../../../services/finance/outcomes/salaries';
import { IOutcomeSalaryResourceShortProps } from '../../../../../../props/finance/outcomes/salaries';
import { NavLink } from 'react-router-dom';
import { IEmployeeBreakdownCommissionResourceShortProps } from '../../../../../../props/users/employees/commission';

interface SalarySlipFormProps {
}

interface FormDataProps {
    paymentMonth: string;
    employee?: IUserResourceShortProps;
    salaryAmount: string;
    bonusAmount: string;
    commissionAmount: string;
    healthInsuranceAmount: string;
    workingInsuranceAmount: string;
    tax: string;
    allowances: AllowanceDataProps[];
    commissions: CommissionDataProps[];
    deductions: DeductionData[];
}

interface AllowanceDataProps {
    id: string;
    name: string;
    notes: string;
    amount: string;
}

type DeductionData = {
    id: string;
    name: string;
    notes: string;
    amount: string;
}

interface CommissionDataProps {
    id: string;
    order?: IOrderResourceShortProps;
    notes: string;
    amount: string;
    paidOn?: string;
    breakdown: CommissionAmountBreakdown[];
    progressive?: boolean;
}

type CommissionAmountBreakdown = {
    profit: number;
    commission: number;
    percentage: number;
}

const SalarySlipForm: React.FC<SalarySlipFormProps> = (props: SalarySlipFormProps) => {
    const { banner, topbar, route } = useStore();

    const urlParams = new URLSearchParams(window.location.search.toLowerCase());
    const employeeId = urlParams.get('employeeid');

    const [data, setData] = React.useState<FormDataProps>({
        paymentMonth: moment().format('MMYYYY'),
        salaryAmount: "",
        bonusAmount: "",
        commissionAmount: "",
        healthInsuranceAmount: "",
        workingInsuranceAmount: "",
        tax: "",
        allowances: [],
        commissions: [],
        deductions: []
    });
    const [existingPayslipLoaded, setExistingPayslipLoaded] = React.useState<boolean>(false);
    const [employeeDetailsLoaded, setEmployeeDetailsLoaded] = React.useState<boolean>(false);
    const [employeeCommissionsLoaded, setEmployeeCommissionsLoaded] = React.useState<boolean>(false);
    const [addingOrderCommission, setAddingOrderCommission] = React.useState<boolean>(false);

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [salary, setSalary] = React.useState<IEmployedSalaryResourceShort>();
    const [contract, setContract] = React.useState<IEmployedContractResourceShort>();
    const [performanceCommissions, setPerformanceCommissions] = React.useState<IEmployeePerformanceCommissionResourceShortProps[]>([]);
    const [performances, setPerformances] = React.useState<IEmployeePerformanceResourceShortProps[]>();
    const [payslip, setPayslip] = React.useState<IOutcomeSalaryResourceShortProps | undefined>();

    useEffect(() => {
        topbar.show('Salary Slip', [
            { key: 'home', icon: faHome },
            { key: 'payroll', text: 'Payroll', href: '/hr/payroll' },
            { key: 'salarySlip', text: 'Salary Slip' }
        ]);

        init();
    }, []);

    const init = async () => {
        try {
            if (employeeId) {
                const _employee = await UserService.get(employeeId);
                data.employee = _employee;
                setData({ ...data });
                _onSelectEmployee(_employee);
            }

            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'initialise_form',
                text: 'Failed to initialise form. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onSearchEmployee = async (keyword: string) => {
        try {
            const qs: string[] = [`rolesGroup=backoffice`, `top=10`, `search=${keyword}`];
            const users = await UserService.retrieve(qs.join('&'));

            return users.data.map((user) => {
                return {
                    id: user.id,
                    text: user.name,
                    secondaryText: user.email,
                    imageInitials: GeneralService.getInitial(user.name),
                    metadata: user,
                };
            });
        } catch (error) {
            banner.add({
                key: "search_employee_error",
                text: "Failed to search related employee. Error: " + ErrorService.getMessage(error),
                variant: 'error',
                icon: faXmarkCircle
            });

            return [];
        }
    }

    const _onResetCommissions = async () => {
        if (data.employee) {
            setEmployeeCommissionsLoaded(false);
            const startDate = moment(data.paymentMonth, "MMYYYY").startOf('month').toISOString();
            const endDate = moment(data.paymentMonth, "MMYYYY").endOf('month').toISOString();

            const _orders = await OrderService.retrieve([
                `sales=${data.employee.id}`,
                `status=completed`,
                `start_final_payment_date=${startDate}`,
                `end_final_payment_date=${endDate}`,
                `top=all`
            ].join("&"));

            // get all related orders
            const _commissions: CommissionDataProps[] = []
            _orders.data.forEach((order) => {
                _commissions.push({
                    id: GeneralService.guid(),
                    amount: "",
                    notes: "",
                    order: order,
                    breakdown: []
                });
            });

            // get all performance based on related orders
            const _performanceCommissions = await _onRetrievePerformanceCommissions(_commissions);

            // count related order commission amount
            data.commissions = countCommissions(_commissions, _performanceCommissions);

            setData({ ...data });
            setPerformanceCommissions([..._performanceCommissions]);

            setEmployeeCommissionsLoaded(true);
        }
    }

    const _onSelectPaymentMonth = async (paymentMonth: string) => {
        setExistingPayslipLoaded(false);
        setPayslip(undefined);

        data.paymentMonth = paymentMonth;
        setData({ ...data });

        const payslip = await OutcomeSalaryService.retrieve([
            `start_payment_month=${moment(data.paymentMonth, 'MMYYYY').startOf('month').toISOString()}`,
            `end_payment_month=${moment(data.paymentMonth, 'MMYYYY').endOf('month').toISOString()}`,
            `employee_id=${data.employee?.id}`
        ].join('&'));

        if (payslip.data[0]) {
            setPayslip(payslip.data[0]);
        } else {
            _onResetCommissions();
        }
        setExistingPayslipLoaded(true);
    }

    const _onSelectEmployee = async (employee: IUserResourceShortProps) => {
        try {
            data.employee = employee;
            setData({ ...data });
            setPerformanceCommissions([]);

            setEmployeeDetailsLoaded(false);

            const _salaries = await EmployeeSalaryService.retrieve(employee.id, 'status=Active');
            const _contracts = await EmployeeContractService.retrieve(employee.id, 'status=Active');

            if (_salaries.data[0]) {
                setSalary(_salaries.data[0]);
                data.salaryAmount = Number(_salaries.data[0].amount) + "";
            }
            if (_contracts.data[0]) { setContract(_contracts.data[0]) }

            setData({ ...data });
            await _onSelectPaymentMonth(data.paymentMonth);

            setEmployeeDetailsLoaded(true);
        } catch (e) {
            banner.add({
                key: 'initialise_form',
                text: 'Failed to initialise form. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onRetrievePerformanceCommissions = async (_commissions: CommissionDataProps[]) => {
        const { employee } = data;
        const _performanceCommissions = performanceCommissions || [];

        if (employee) {
            const requiredMonth: string[] = [];
            _commissions.forEach((commission) => {
                if (commission.order && commission.order.actualDeparture) {
                    const departureDate = moment(commission.order.actualDeparture);
                    const performanceCommissionExist = (_performanceCommissions || []).findIndex((perf) => {
                        const startDate = moment(perf.month).startOf('month');
                        const endDate = moment(perf.month).endOf('month');
                        return departureDate.isBetween(startDate, endDate);
                    }) > -1;

                    if (!performanceCommissionExist && requiredMonth.indexOf(departureDate.format("MMYYYY")) < 0) {
                        requiredMonth.push(departureDate.format("MMYYYY"));
                    }
                }
            });

            for (var ctr = 0; ctr < requiredMonth.length; ctr++) {
                const month = moment(requiredMonth[ctr], "MMYYYY").startOf('month').add(1, 'days').toISOString();
                const _performanceCommission = await EmployeePerformanceService.commission.get(employee.id, [
                    `month=${month}`
                ].join("&"));
                _performanceCommissions.push(_performanceCommission);
            }
        }

        return _performanceCommissions;
    }

    const countCommissions = (_commissions: CommissionDataProps[], _performanceCommissions: IEmployeePerformanceCommissionResourceShortProps[]) => {
        _performanceCommissions.forEach((pCom) => {
            let totalProfit = 0;

            // get paid order first
            pCom.relatedOrders.forEach((relatedOrder) => {
                const cidx = _commissions.findIndex((commission) => commission.order?.id === relatedOrder.order.id);

                if (cidx > -1 && _commissions[cidx] && relatedOrder.commission) {
                    _commissions[cidx].paidOn = relatedOrder.commission.paidOn;
                    _commissions[cidx].amount = relatedOrder.commission.amount.toFixed(2);
                    
                    if (relatedOrder.salesProfit > 0) {
                        totalProfit += relatedOrder.salesProfit;
                    }
                }
            })

            // get unpaid orders
            pCom.relatedOrders.forEach((relatedOrder) => {
                if (relatedOrder.salesProfit > 0) {
                    const cidx = _commissions.findIndex((commission) => commission.order?.id === relatedOrder.order.id);
                    
                    if (cidx > -1 && _commissions[cidx] && !relatedOrder.commission) {
                        // check current profit bracket
                        const currentProfitBracketIdx = pCom.commissionBracket.findIndex((cBracket) => !cBracket.endAmount || totalProfit <= Number(cBracket.endAmount));
                        const newProfitBracketIdx = pCom.commissionBracket.findIndex((cBracket) => !cBracket.endAmount || (totalProfit + relatedOrder.salesProfit) <= Number(cBracket.endAmount));

                        if (currentProfitBracketIdx > -1 && newProfitBracketIdx > -1 && newProfitBracketIdx === currentProfitBracketIdx) {
                            const commissionPercentage = Number(pCom.commissionBracket[newProfitBracketIdx].commissionPercentage);
                            _commissions[cidx].progressive = true;
                            _commissions[cidx].amount = (relatedOrder.salesProfit * commissionPercentage / 100).toFixed(2);
                            _commissions[cidx].breakdown = [{ profit: relatedOrder.salesProfit, commission: Number(_commissions[cidx].amount), percentage: commissionPercentage }];
                        } else {
                            _commissions[cidx].breakdown = [];
                            let amount = 0;
                            let leftover = relatedOrder.salesProfit;

                            let tempProfit = totalProfit;
                            for (var ctr = currentProfitBracketIdx; ctr < (newProfitBracketIdx + 1); ctr++) {
                                const commissionBracket = pCom.commissionBracket[ctr];
                                let profitAmount = 0;

                                if (commissionBracket.endAmount) {
                                    profitAmount = Number(commissionBracket.endAmount) - tempProfit;

                                    if (leftover < profitAmount) {
                                        profitAmount = leftover;
                                        leftover = 0;
                                    } else {
                                        leftover -= profitAmount;
                                    }
                                } else {
                                    profitAmount = (totalProfit + relatedOrder.salesProfit - tempProfit);
                                }

                                const commissionAmount = Number((profitAmount * Number(commissionBracket.commissionPercentage) / 100).toFixed(2));

                                amount += commissionAmount;
                                tempProfit = Number(commissionBracket.endAmount);
                                _commissions[cidx].breakdown.push({
                                    profit: profitAmount,
                                    commission: commissionAmount,
                                    percentage: Number(commissionBracket.commissionPercentage),
                                });
                            }

                            _commissions[cidx].progressive = true;
                            _commissions[cidx].amount = amount.toFixed(2);
                        }

                        totalProfit += relatedOrder.salesProfit;
                    }
                }
            })
        })

        _commissions.forEach((com) => {
            const salesCommission = (com.order?.sales || []).find((s) => s.sales.id === data.employee?.id);
            const paidCommission = (com.order?.salesCommissions || []).find((s) => s.employee.id === data.employee?.id);

            if (!com.progressive && !paidCommission && salesCommission && (salesCommission.feePercentage || salesCommission.feePercentage)) {
                com.amount = ((Number(com.order?.nettProfit || 0)) * salesCommission.feePercentage / 100).toFixed(2);
            } else if (!com.progressive && !paidCommission && salesCommission && (salesCommission.feeAmount || salesCommission.feeAmount)) {
                com.amount = ((Number(com.order?.nettProfit || 0)) + salesCommission.feeAmount).toFixed(2);
            }
        });

        return _commissions;
    }

    const countTotalSalary = () => {
        const { salaryAmount, bonusAmount, healthInsuranceAmount, workingInsuranceAmount, tax, allowances, commissions, deductions } = data;
        let total = 0;

        total += Number(salaryAmount);
        total += Number(bonusAmount);
        total -= Number(healthInsuranceAmount);
        total -= Number(workingInsuranceAmount);
        total -= Number(tax);

        allowances.forEach((allowance) => {
            total += Number(allowance.amount);
        });

        deductions.forEach((deduction) => {
            total -= Number(deduction.amount);
        });

        commissions.forEach((commission) => {
            total += Number(commission.amount);
        })

        return total;
    }

    const isSubmitButtonDisabled = () => {
        const { employee } = data;

        if (!employee) {
            return true;
        } else if (!employeeDetailsLoaded || !employeeCommissionsLoaded) {
            return true;
        }

        return false;
    }

    const _onSearchOrder = async (keyword?: string): Promise<any> => {
        try {
            if (data.employee && keyword) {
                const qs = [];
                qs.push(`sales=${data.employee.id}`);
                qs.push(`payment_status=paid`);
                qs.push(`top=all`);
                if (keyword) { qs.push(`search=${keyword}`) }

                const _orders = await OrderService.retrieve(qs.join("&"));

                return _orders.data.filter((order) => {
                    return (order.salesCommissions || []).findIndex((com) => com.employee.id === data.employee?.id) < 0;
                }).filter((order) => {
                    return data.commissions.findIndex((com) => com.order?.id === order.id) < 0;
                }).map((order) => {
                    return {
                        key: order.id,
                        name: order.orderNumber,
                        metadata: order
                    }
                })
            }
        } catch (e) {
            banner.add({
                key: 'search_order',
                text: 'Failed to search order. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
            const { employee, salaryAmount, bonusAmount, paymentMonth, healthInsuranceAmount, workingInsuranceAmount, tax, allowances, deductions, commissions } = data;

            const fd = new FormData();
            fd.append('employeeId', employee?.id || "");
            fd.append('paymentMonth', moment(paymentMonth, "MMYYYY").startOf('month').toISOString());

            fd.append('salaryAmount', salaryAmount.trim() !== "" ? salaryAmount : "0");
            fd.append('bonusAmount', bonusAmount.trim() !== "" ? bonusAmount : "0");
            fd.append('healthInsuranceAmount', healthInsuranceAmount.trim() !== "" ? healthInsuranceAmount : "0");
            fd.append('workingInsuranceAmount', workingInsuranceAmount.trim() !== "" ? workingInsuranceAmount : "0");
            fd.append('taxAmount', tax.trim() !== "" ? tax : "0");

            allowances.forEach((allowance) => {
                fd.append('allowances[]', JSON.stringify(allowance));
            });

            deductions.forEach((deduction) => {
                fd.append('deductions[]', JSON.stringify(deduction));
            });

            commissions.forEach((commission) => {
                if (commission.order) {
                    fd.append('commissions[]', JSON.stringify({
                        orderId: commission.order?.id,
                        amount: commission.amount,
                        employeeId: employee?.id || ""
                    }));
                }
            })

            const salary = await OutcomeSalaryService.create(fd);
            route.route?.history.push(`/users/administrators/${employee?.id}/payslips/${salary.id}`);
        } catch (e) {
            setSubmitting(false);
            banner.add({
                key: 'submit_salary_slip',
                text: 'Failed to submit salary slip. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    return <Stack horizontal tokens={{ childrenGap: 20 }}>
        {!loaded ? <LoadingComponent label={'Retrieving form ...'} labelPosition='right' spinnerPosition={'baseline'} /> : null}
        {loaded ? <>
            <Stack tokens={{ childrenGap: 20 }} grow={1}>
                <Stack className={styles.container} tokens={{ childrenGap: 10 }}>
                    <Label>Employee Details</Label>
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack styles={{ root: { width: '25%' } }}>
                            <Label size={'xsmall'} style={{ paddingTop: 1, paddingBottom: 3 }} required={true}>Select employee</Label>
                            <NormalPeoplePicker
                                onResolveSuggestions={_onSearchEmployee}
                                onEmptyResolveSuggestions={() => _onSearchEmployee('')}
                                itemLimit={1}
                                disabled={submitting || employeeId != undefined}
                                selectedItems={data.employee ? [{
                                    id: data.employee.id,
                                    text: data.employee.name,
                                    secondaryText: data.employee.email,
                                    imageInitials: GeneralService.getInitial(data.employee.name)
                                }] : []}
                                onChange={(item?: any) => {
                                    if (item[0]?.metadata) {
                                        _onSelectEmployee(item[0].metadata);
                                    } else {
                                        data.employee = undefined;
                                        setData({ ...data });
                                    }
                                    return null;
                                }}
                            />
                        </Stack>
                        {data.employee && !employeeDetailsLoaded ? <Stack styles={{ root: { paddingTop: 32 } }}>
                            <LoadingComponent size={SpinnerSize.xSmall}
                                label='Retrieving employee details ...'
                                labelPosition='right'
                                spinnerPosition='baseline' />
                        </Stack> : null}
                        {data.employee && employeeDetailsLoaded ? <>
                            <Stack styles={{ root: { width: '25%' } }}>
                                <TextField label={'Current salary'}
                                    disabled={true}
                                    suffix={"/ month"}
                                    value={`Rp. ${GeneralService.getNumberWithSeparator(Number(salary?.amount || "0"))}`} />
                            </Stack>
                            <Stack styles={{ root: { width: '25%' } }} tokens={{ childrenGap: 10 }}>
                                <TextField label={'Contract number'}
                                    disabled={true}
                                    value={contract ? "#" + contract.contractNumber : "-"} />
                            </Stack>
                            <Stack.Item styles={{ root: { width: '25%' } }}>
                                <Dropdown label={'Select payment month'}
                                    selectedKey={data.paymentMonth}
                                    disabled={submitting}
                                    options={[
                                        { key: moment().add(-3, 'month').format('MMYYYY'), text: moment().add(-3, 'month').format('MMMM YYYY') },
                                        { key: moment().add(-2, 'month').format('MMYYYY'), text: moment().add(-2, 'month').format('MMMM YYYY') },
                                        { key: moment().add(-1, 'month').format('MMYYYY'), text: moment().add(-1, 'month').format('MMMM YYYY') },
                                        { key: moment().format('MMYYYY'), text: moment().format('MMMM YYYY') },
                                        { key: moment().add(1, 'month').format('MMYYYY'), text: moment().add(1, 'month').format('MMMM YYYY') }
                                    ]}
                                    onChange={async (ev, opt) => {
                                        _onSelectPaymentMonth(((opt?.key || data.paymentMonth) as string));
                                    }} />
                            </Stack.Item>
                        </> : null}
                    </Stack>
                </Stack>
                {!existingPayslipLoaded && data.employee ? <LoadingComponent label={'Retrieving form ...'} labelPosition='right' spinnerPosition='baseline' /> : null}
                {payslip && existingPayslipLoaded ? <Stack className={styles.container} tokens={{childrenGap: 10}}>
                    <Text>Payslip for {moment(data.paymentMonth, 'MMYYYY').format('MMMM YYYY')} already exist. <NavLink to={`/users/administrators/${data.employee?.id}/payslips/${payslip.id}`}>Click here</NavLink> to open payslip details.</Text>
                </Stack> : null}
                {!payslip && existingPayslipLoaded && data.employee && employeeDetailsLoaded ? <>
                    <Stack className={styles.container} tokens={{ childrenGap: 10 }}>
                        <Label>Salary and Deductions</Label>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                                <Stack styles={{ root: { width: '25%' } }}>
                                    <TextField label={'Salary amount'}
                                        prefix={'Rp'}
                                        disabled={submitting}
                                        value={data.salaryAmount}
                                        onChange={(ev, value) => {
                                            if (value === "" || !isNaN(Number(value))) {
                                                data.salaryAmount = value || '';
                                                setData({ ...data });
                                            }
                                        }} />
                                </Stack>
                                <Stack styles={{ root: { width: '25%' } }}>
                                    <TextField label={'Bonus amount'}
                                        prefix={'Rp'}
                                        disabled={submitting}
                                        value={data.bonusAmount}
                                        onChange={(ev, value) => {
                                            if (value === "" || !isNaN(Number(value))) {
                                                data.bonusAmount = value || '';
                                                setData({ ...data });
                                            }
                                        }} />
                                </Stack>
                                <Stack styles={{ root: { width: '25%' } }}></Stack>
                                <Stack styles={{ root: { width: '25%' } }}></Stack>
                            </Stack>
                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                                <Stack styles={{ root: { width: '25%' } }}>
                                    <TextField label={'Health insurance cost'}
                                        prefix={'Rp'}
                                        disabled={submitting}
                                        value={data.healthInsuranceAmount}
                                        onChange={(ev, value) => {
                                            if (value === "" || !isNaN(Number(value))) {
                                                data.healthInsuranceAmount = value || '';
                                                setData({ ...data });
                                            }
                                        }} />
                                </Stack>
                                <Stack styles={{ root: { width: '25%' } }}>
                                    <TextField label={'Working insurance cost'}
                                        prefix={'Rp'}
                                        disabled={submitting}
                                        value={data.workingInsuranceAmount}
                                        onChange={(ev, value) => {
                                            if (value === "" || !isNaN(Number(value))) {
                                                data.workingInsuranceAmount = value || '';
                                                setData({ ...data });
                                            }
                                        }} />
                                </Stack>
                                <Stack styles={{ root: { width: '25%' } }}>
                                    <TextField label={'Tax'}
                                        disabled={submitting}
                                        prefix={'Rp'}
                                        value={data.tax}
                                        onChange={(ev, value) => {
                                            if (value === "" || !isNaN(Number(value))) {
                                                data.tax = value || '';
                                                setData({ ...data });
                                            }
                                        }} />
                                </Stack>
                                <Stack styles={{ root: { width: '25%' } }}></Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack className={styles.container}>
                        <Stack className={styles.header} horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                            <Label>Allowances</Label>
                            <DefaultButton text={'Add allowance'}
                                iconProps={{ iconName: 'Add' }}
                                disabled={submitting}
                                onClick={() => {
                                    data.allowances.push({
                                        amount: "",
                                        name: "",
                                        notes: "",
                                        id: GeneralService.guid()
                                    });
                                    setData({ ...data });
                                }} />
                        </Stack>
                        <Stack>
                            {data.allowances.length < 1 ? <Stack styles={{ root: { paddingTop: 10 } }}><Text>No allowances listed</Text></Stack> : null}
                            {data.allowances.length > 0 ? <DetailsList items={data.allowances}
                                selectionMode={SelectionMode.none}
                                columns={[
                                    {
                                        key: "no",
                                        name: "No.",
                                        minWidth: 30,
                                        maxWidth: 30,
                                        onRender: (item: AllowanceDataProps, idx?: number) => {
                                            return <Stack styles={{ root: { padding: '4px 0px' } }}>
                                                <Text size={'small'}>{(idx || 0) + 1}</Text>
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "name",
                                        name: "Name",
                                        minWidth: 200,
                                        maxWidth: 200,
                                        onRender: (item: AllowanceDataProps, idx?: number) => {
                                            return <Stack>
                                                <TextField value={item.name}
                                                    disabled={submitting}
                                                    onChange={(ev, value) => {
                                                        data.allowances[idx || 0].name = value || "";
                                                        setData({ ...data });
                                                    }} />
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "notes",
                                        name: "Notes",
                                        minWidth: 200,
                                        isMultiline: true,
                                        onRender: (item: AllowanceDataProps, idx?: number) => {
                                            return <Stack>
                                                <TextField value={item.notes}
                                                    disabled={submitting}
                                                    onChange={(ev, value) => {
                                                        data.allowances[idx || 0].notes = value || "";
                                                        setData({ ...data });
                                                    }} />
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "amount",
                                        name: "Amount",
                                        minWidth: 175,
                                        maxWidth: 175,
                                        onRender: (item: AllowanceDataProps, idx?: number) => {
                                            return <Stack>
                                                <TextField value={item.amount}
                                                    disabled={submitting}
                                                    prefix={'Rp'}
                                                    onChange={(ev, value) => {
                                                        if (value === "" || !isNaN(Number(value))) {
                                                            data.allowances[idx || 0].amount = value || "";
                                                            setData({ ...data });
                                                        }
                                                    }} />
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "action",
                                        name: "",
                                        minWidth: 50,
                                        maxWidth: 50,
                                        onRender: (item: AllowanceDataProps, idx?: number) => {
                                            return <Stack horizontal>
                                                <ActionButton iconProps={{ iconName: "Delete" }}
                                                    disabled={submitting}
                                                    onClick={() => {
                                                        data.allowances = data.allowances.filter((a) => a.id !== item.id);
                                                        setData({ ...data });
                                                    }} />
                                            </Stack>
                                        }
                                    }
                                ]} /> : null}
                        </Stack>
                    </Stack>
                    <Stack className={styles.container}>
                        <Stack className={styles.header} horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                            <Label>Deductions</Label>
                            <DefaultButton text={'Add deduction'}
                                iconProps={{ iconName: 'Add' }}
                                disabled={submitting}
                                onClick={() => {
                                    data.deductions.push({
                                        amount: "",
                                        name: "",
                                        notes: "",
                                        id: GeneralService.guid()
                                    });
                                    setData({ ...data });
                                }} />
                        </Stack>
                        <Stack>
                            {data.deductions.length < 1 ? <Stack styles={{ root: { paddingTop: 10 } }}><Text>No deductions listed</Text></Stack> : null}
                            {data.deductions.length > 0 ? <DetailsList items={data.deductions}
                                selectionMode={SelectionMode.none}
                                columns={[
                                    {
                                        key: "no",
                                        name: "No.",
                                        minWidth: 30,
                                        maxWidth: 30,
                                        onRender: (item: DeductionData, idx?: number) => {
                                            return <Stack styles={{ root: { padding: '4px 0px' } }}>
                                                <Text size={'small'}>{(idx || 0) + 1}</Text>
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "name",
                                        name: "Name",
                                        minWidth: 200,
                                        maxWidth: 200,
                                        onRender: (item: DeductionData, idx?: number) => {
                                            return <Stack>
                                                <TextField value={item.name}
                                                    disabled={submitting}
                                                    onChange={(ev, value) => {
                                                        data.deductions[idx || 0].name = value || "";
                                                        setData({ ...data });
                                                    }} />
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "notes",
                                        name: "Notes",
                                        minWidth: 200,
                                        isMultiline: true,
                                        onRender: (item: DeductionData, idx?: number) => {
                                            return <Stack>
                                                <TextField value={item.notes}
                                                    disabled={submitting}
                                                    onChange={(ev, value) => {
                                                        data.deductions[idx || 0].notes = value || "";
                                                        setData({ ...data });
                                                    }} />
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "amount",
                                        name: "Amount",
                                        minWidth: 175,
                                        maxWidth: 175,
                                        onRender: (item: DeductionData, idx?: number) => {
                                            return <Stack>
                                                <TextField value={item.amount}
                                                    disabled={submitting}
                                                    prefix={'Rp'}
                                                    onChange={(ev, value) => {
                                                        if (value === "" || !isNaN(Number(value))) {
                                                            data.deductions[idx || 0].amount = value || "";
                                                            setData({ ...data });
                                                        }
                                                    }} />
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "action",
                                        name: "",
                                        minWidth: 50,
                                        maxWidth: 50,
                                        onRender: (item: DeductionData, idx?: number) => {
                                            return <Stack horizontal>
                                                <ActionButton iconProps={{ iconName: "Delete" }}
                                                    disabled={submitting}
                                                    onClick={() => {
                                                        data.deductions = data.deductions.filter((a) => a.id !== item.id);
                                                        setData({ ...data });
                                                    }} />
                                            </Stack>
                                        }
                                    }
                                ]} /> : null}
                        </Stack>
                    </Stack>
                    <Stack className={styles.container}>
                        <Stack className={styles.header} horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                            <Label>Order Commissions</Label>
                            <DefaultButton text={'Add order commission'}
                                disabled={submitting}
                                iconProps={{ iconName: 'Add' }}
                                onClick={() => {
                                    data.commissions.push({
                                        amount: "",
                                        notes: "",
                                        id: GeneralService.guid(),
                                        breakdown: []
                                    });
                                    setData({ ...data });
                                }} />
                        </Stack>
                        <Stack>
                            {!employeeCommissionsLoaded ? <LoadingComponent size={SpinnerSize.xSmall} label={"Retrieving commissions ..."} labelPosition='right' spinnerPosition='baseline' /> : null}
                            {data.commissions.length < 1 && employeeCommissionsLoaded ? <Stack styles={{ root: { paddingTop: 10 } }}><Text>No order commissions listed</Text></Stack> : null}
                            {data.commissions.length > 0 && employeeCommissionsLoaded ? <DetailsList items={data.commissions}
                                selectionMode={SelectionMode.none}
                                columns={[
                                    {
                                        key: "no",
                                        name: "No.",
                                        minWidth: 30,
                                        maxWidth: 30,
                                        onRender: (item: CommissionDataProps, idx?: number) => {
                                            return <Stack styles={{ root: { padding: '4px 0px' } }}>
                                                <Text size={'small'}>{(idx || 0) + 1}</Text>
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "order",
                                        name: "Order Details",
                                        minWidth: 200,
                                        onRender: (item: CommissionDataProps, idx?: number) => {
                                            const goodsType = DeliveredGoodsType.find((type) => type.key === item.order?.goodsType);

                                            return <Stack styles={{ root: { padding: '4px 0px' } }} tokens={{ childrenGap: 5 }}>
                                                {!item.order ? <TagPicker
                                                    disabled={submitting}
                                                    selectedItems={[]}
                                                    removeButtonAriaLabel='Remove'
                                                    itemLimit={1}
                                                    onItemSelected={async (order: any) => {
                                                        setAddingOrderCommission(true);

                                                        data.commissions[idx || 0].order = order.metadata;

                                                        // retrieve all new performances
                                                        const _performanceCommissions = await _onRetrievePerformanceCommissions(data.commissions);

                                                        // count order commissions amount
                                                        data.commissions = countCommissions(data.commissions, _performanceCommissions);

                                                        setPerformanceCommissions([..._performanceCommissions]);
                                                        setData({ ...data });
                                                        setAddingOrderCommission(false);

                                                        return order;
                                                    }}
                                                    onRenderSuggestionsItem={(item: any) => {
                                                        const order = item.metadata as IOrderResourceShortProps;
                                                        const goodsType = DeliveredGoodsType.find((type) => type.key === order.goodsType);

                                                        return <Stack tokens={{ childrenGap: 3 }} horizontalAlign={'baseline'}>
                                                            <Stack grow={1} styles={{ root: { width: '100%' } }} horizontal horizontalAlign={'space-between'} tokens={{ childrenGap: 20 }} verticalAlign='center'>
                                                                <Label size={'small'}>#{OrderService.getOrderNumber(order.orderNumber)}</Label>
                                                                <Text size={'small'} className={styles.income}>Rp. {GeneralService.getNumberWithSeparator(Number(order.totalAmount).toFixed(2))}</Text>
                                                            </Stack>
                                                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                                                <Stack styles={{ root: { width: 70 } }} horizontalAlign={'baseline'}><Text size={'small'}>Category</Text></Stack>
                                                                <Text size={'small'}>:</Text>
                                                                <Text size={'small'}>{order.freightCategory.name} - {order.deliveryType.name} - {goodsType?.text}</Text>
                                                            </Stack>
                                                            {order.actualArrival ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                                                                <Stack styles={{ root: { width: 70 } }} horizontalAlign={'baseline'}><Text size={'small'}>Arrive at</Text></Stack>
                                                                <Text size={'small'}>:</Text>
                                                                <Text size={'small'}>{moment(order.actualDeparture).format("DD/MM/YYYY")}</Text>
                                                            </Stack> : null}
                                                            {order.finalPaymentDate ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                                                                <Stack styles={{ root: { width: 70 } }} horizontalAlign={'baseline'}><Text size={'small'}>Paid on</Text></Stack>
                                                                <Text size={'small'}>:</Text>
                                                                <Text size={'small'}>{moment(order.finalPaymentDate).format("DD/MM/YYYY")}</Text>
                                                            </Stack> : null}
                                                            {order.batch ? <Stack horizontal tokens={{ childrenGap: 10 }}>
                                                                <Stack styles={{ root: { width: 70 } }} horizontalAlign={'baseline'}><Text size={'small'}>Batch</Text></Stack>
                                                                <Text size={'small'}>:</Text>
                                                                <Text size={'small'}>{order.batch}</Text>
                                                            </Stack> : null}
                                                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                                                <Stack styles={{ root: { width: 70 } }} horizontalAlign={'baseline'}><Text size={'small'}>Origin</Text></Stack>
                                                                <Text size={'small'}>:</Text>
                                                                <ul style={{ padding: 0, paddingLeft: 10, margin: 0, listStyleType: 'none' }}>
                                                                    {order.originAddresses.map((address, idx) => {
                                                                        const addr = [address.address];
                                                                        if (address.city) {
                                                                            addr.push(address.city);
                                                                        }
                                                                        if (address.province) {
                                                                            addr.push(address.province);
                                                                        }

                                                                        return <li><Text size={'small'}>{(idx || 0) + 1}. {address.type} - {addr.join(", ")}</Text></li>
                                                                    })}
                                                                </ul>
                                                            </Stack>
                                                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                                                <Stack styles={{ root: { width: 70 } }} horizontalAlign={'baseline'}><Text size={'small'}>Destination</Text></Stack>
                                                                <Text size={'small'}>:</Text>
                                                                <ul style={{ padding: 0, paddingLeft: 10, margin: 0, listStyleType: 'none' }}>
                                                                    {order.destinationAddresses.map((address, idx) => {
                                                                        const addr = [address.address];
                                                                        if (address.city) {
                                                                            addr.push(address.city);
                                                                        }
                                                                        if (address.province) {
                                                                            addr.push(address.province);
                                                                        }

                                                                        return <li><Text size={'small'}>{(idx || 0) + 1}. {address.type} - {addr.join(", ")}</Text></li>
                                                                    })}
                                                                </ul>
                                                            </Stack>
                                                        </Stack>
                                                    }}
                                                    onResolveSuggestions={_onSearchOrder}
                                                    onEmptyResolveSuggestions={() => _onSearchOrder('')}
                                                /> : null}
                                                {item.order ? <>
                                                    <Label size={'xsmall'}>
                                                        <Link href={`/orders/${item.order.id}`} target={'_blank'}>#{OrderService.getOrderNumber(item.order.orderNumber)}</Link>
                                                    </Label>
                                                    <Text size={'xsmall'}>{item.order.freightCategory.name} - {item.order.deliveryType.name} - {goodsType?.text}</Text>
                                                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                                                        <Stack styles={{ root: { width: 75 } }}>
                                                            <Text size={'xsmall'}>Start date</Text>
                                                        </Stack>
                                                        <Text size={'xsmall'}>:</Text>
                                                        {item.order.actualDeparture ? <Text size={'xsmall'}>{moment(item.order.actualDeparture).format("DD/MM/YYYY")}</Text> : null}
                                                    </Stack>
                                                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                                                        <Stack styles={{ root: { width: 75 } }}>
                                                            <Text size={'xsmall'}>Paid on</Text>
                                                        </Stack>
                                                        <Text size={'xsmall'}>:</Text>
                                                        {item.order.finalPaymentDate ? <Text size={'xsmall'}>{moment(item.order.finalPaymentDate).format("DD/MM/YYYY")}</Text> : null}
                                                    </Stack>
                                                    <Stack className={'divider'}></Stack>
                                                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                                                        <Stack styles={{ root: { width: '50%' } }}>
                                                            <ol style={{ padding: 0, paddingLeft: 12 }}>
                                                                {item.order.originAddresses.map((address) => {
                                                                    const addr = [address.address];
                                                                    if (address.city) {
                                                                        addr.push(address.city);
                                                                    }
                                                                    if (address.province) {
                                                                        addr.push(address.province);
                                                                    }

                                                                    return <li><Text size={'xsmall'}>{address.type} - {addr.join(", ")}</Text></li>
                                                                })}
                                                            </ol>
                                                        </Stack>
                                                        <FontAwesomeIcon icon={faArrowRight} style={{ paddingTop: 3 }} />
                                                        <Stack styles={{ root: { width: '50%' } }}>
                                                            <ol style={{ padding: 0, paddingLeft: 12 }}>
                                                                {item.order.destinationAddresses.map((address) => {
                                                                    const addr = [address.address];
                                                                    if (address.city) {
                                                                        addr.push(address.city);
                                                                    }
                                                                    if (address.province) {
                                                                        addr.push(address.province);
                                                                    }

                                                                    return <li><Text size={'xsmall'}>{address.type} - {addr.join(", ")}</Text></li>
                                                                })}
                                                            </ol>
                                                        </Stack>
                                                    </Stack>
                                                </> : null}
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "customer",
                                        name: "Customer",
                                        minWidth: 125,
                                        maxWidth: 125,
                                        isMultiline: true,
                                        onRender: (item: CommissionDataProps, idx?: number) => {
                                            return <Stack styles={{ root: { padding: '4px 0px' } }} tokens={{ childrenGap: 3 }}>
                                                {item.order ? <>
                                                    <Text size={'small'}>{item.order.customer.name}</Text>
                                                    {item.order.company ? <Text size={'small'}>({item.order.company.name})</Text> : null}
                                                </> : null}
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "price",
                                        name: "Price",
                                        minWidth: 125,
                                        maxWidth: 125,
                                        isMultiline: true,
                                        onRender: (item: CommissionDataProps, idx?: number) => {
                                            return <Stack styles={{ root: { padding: '4px 0px' } }} tokens={{ childrenGap: 3 }}>
                                                {item.order ? <Text size='small'>Rp. {GeneralService.getNumberWithSeparator(Number(Number(item.order.totalAmount || "0").toFixed(2)))}</Text> : null}
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "profit",
                                        name: "Company Profit",
                                        minWidth: 125,
                                        maxWidth: 125,
                                        isMultiline: true,
                                        onRender: (item: CommissionDataProps, idx?: number) => {
                                            return <Stack styles={{ root: { padding: '4px 0px' } }} tokens={{ childrenGap: 3 }}>
                                                {item.order ? <Text size='small' className={styles.income}>Rp. {GeneralService.getNumberWithSeparator(Number(Number(item.order.nettProfit || "0").toFixed(2)))}</Text> : null}
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "commission",
                                        name: "Commission",
                                        minWidth: 175,
                                        maxWidth: 175,
                                        onRender: (item: CommissionDataProps, idx?: number) => {
                                            return <Stack styles={{ root: { padding: '4px 0px' } }}>
                                                <Text size='small' className={styles.income}>Rp. {GeneralService.getNumberWithSeparator(Number(item.amount))}</Text>
                                            </Stack>
                                        }
                                    },
                                    {
                                        key: "action",
                                        name: "",
                                        minWidth: 50,
                                        maxWidth: 50,
                                        onRender: (item: CommissionDataProps, idx?: number) => {
                                            return <Stack horizontal>
                                                <ActionButton iconProps={{ iconName: "Delete" }}
                                                    disabled={submitting}
                                                    onClick={() => {
                                                        let _commissions = data.commissions.filter((a) => a.id !== item.id);
                                                        _commissions = countCommissions(_commissions, performanceCommissions || []);

                                                        data.commissions = _commissions;
                                                        setData({ ...data });
                                                    }} />
                                            </Stack>
                                        }
                                    }
                                ]} /> : null}
                        </Stack>
                    </Stack>
                </> : null}
            </Stack>
            <Stack tokens={{ childrenGap: 20 }} styles={{ root: { width: 275 } }}>
                <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
                    <Stack className={styles.header}>
                        <Label>Summary</Label>
                    </Stack>
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                            <Text size={'small'}>Employee</Text>
                            <Label size={'small'}>{data.employee?.name}</Label>
                        </Stack>
                        {data.employee ? <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                            <Text size={'small'}>Payment month</Text>
                            <Label size={'small'}>{moment(data.paymentMonth, "MMYYYY").format("MMM YYYY")}</Label>
                        </Stack> : null}
                    </Stack>
                    {data.employee && !payslip && existingPayslipLoaded ? <>
                        <Stack className={'divider'}></Stack>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                                <Text size={'small'}>Salary</Text>
                                <Label size={'small'} className={styles.income}>+ Rp. {GeneralService.getNumberWithSeparator(data.salaryAmount)}</Label>
                            </Stack>
                            <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                                <Text size={'small'}>Bonus</Text>
                                <Label size={'small'} className={styles.income}>+ Rp. {GeneralService.getNumberWithSeparator(data.bonusAmount)}</Label>
                            </Stack>
                        </Stack>
                        <Stack className={'divider'}></Stack>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                                <Text size={'small'}>Health insurance</Text>
                                <Label size={'small'} className={styles.deduction}>- Rp. {GeneralService.getNumberWithSeparator(data.healthInsuranceAmount)}</Label>
                            </Stack>
                            <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                                <Text size={'small'}>Working insurance</Text>
                                <Label size={'small'} className={styles.deduction}>- Rp. {GeneralService.getNumberWithSeparator(data.workingInsuranceAmount)}</Label>
                            </Stack>
                            <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                                <Text size={'small'}>Tax</Text>
                                <Label size={'small'} className={styles.deduction}>- Rp. {GeneralService.getNumberWithSeparator(data.tax)}</Label>
                            </Stack>
                        </Stack>
                        {data.allowances.length > 0 ? <>
                            <Stack className={'divider'}></Stack>
                            <Stack tokens={{ childrenGap: 10 }}>
                                <Label size={'xsmall'}>Allowances</Label>
                                {data.allowances.filter((al) => al.name.trim() !== "" || al.amount.trim() !== "").map((allowance) => {
                                    return <Stack horizontal horizontalAlign={'space-between'}>
                                        <Stack styles={{ root: { width: 120, paddingTop: 2 } }}>
                                            <Text size={'small'}>{allowance.name.trim() !== "" ? allowance.name : "-"}</Text>
                                            {allowance.notes.trim() !== "" ? <Text size={'xsmall'}>{allowance.notes}</Text> : null}
                                        </Stack>
                                        <Stack grow={1} horizontalAlign={'end'}>
                                            <Label size={'small'} className={styles.income}>+ Rp. {GeneralService.getNumberWithSeparator(allowance.amount)}</Label>
                                        </Stack>
                                    </Stack>
                                })}
                            </Stack>
                        </> : null}
                        {data.deductions.length > 0 ? <>
                            <Stack className={'divider'}></Stack>
                            <Stack tokens={{ childrenGap: 10 }}>
                                <Label size={'xsmall'}>Deductions</Label>
                                {data.deductions.filter((al) => al.name.trim() !== "" || al.amount.trim() !== "").map((deduction) => {
                                    return <Stack horizontal horizontalAlign={'space-between'}>
                                        <Stack styles={{ root: { width: 120, paddingTop: 2 } }}>
                                            <Text size={'small'}>{deduction.name.trim() !== "" ? deduction.name : "-"}</Text>
                                            {deduction.notes.trim() !== "" ? <Text size={'xsmall'}>{deduction.notes}</Text> : null}
                                        </Stack>
                                        <Stack grow={1} horizontalAlign={'end'}>
                                            <Label size={'small'} className={styles.deduction}>+ Rp. {GeneralService.getNumberWithSeparator(deduction.amount)}</Label>
                                        </Stack>
                                    </Stack>
                                })}
                            </Stack>
                        </> : null}
                        {data.commissions.length > 0 ? <>
                            <Stack className={'divider'}></Stack>
                            <Stack tokens={{ childrenGap: 10 }}>
                                <Label size={'xsmall'}>Order commissions</Label>
                                {data.commissions.filter((co) => co.order).map((commission) => {
                                    return <Stack horizontal horizontalAlign={'space-between'}>
                                        <Stack styles={{ root: { width: 120, paddingTop: 2 } }}>
                                            <Text size={'small'}>#{OrderService.getOrderNumber(commission.order?.orderNumber || "")}</Text>
                                        </Stack>
                                        <Stack grow={1} horizontalAlign={'end'}>
                                            <Label size={'small'} className={styles.income}>+ Rp. {GeneralService.getNumberWithSeparator(commission.amount)}</Label>
                                        </Stack>
                                    </Stack>
                                })}
                            </Stack>
                        </> : null}
                        <Stack className={'divider'}></Stack>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'}>
                                <Text size={'small'}>Total</Text>
                                <Label size={'small'}>Rp. {GeneralService.getNumberWithSeparator(countTotalSalary())}</Label>
                            </Stack>
                        </Stack>
                    </> : null}
                </Stack>
                <Stack>
                    {!submitting && !payslip && existingPayslipLoaded ? <PrimaryButton disabled={isSubmitButtonDisabled()} text={'Submit'} onClick={_onSubmit} /> : null}
                    {submitting ? <LoadingComponent label={'Submitting ...'} labelPosition='right' /> : null}
                </Stack>
            </Stack>
        </> : null}
        {addingOrderCommission ? <LoadingDialogComponent title='Adding Order' secondaryText='Working on it ...' /> : null}
    </Stack>;
};

export default SalarySlipForm;
