import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';

// assets
import { faClose } from '@fortawesome/pro-light-svg-icons';

// services
import GeneralService from '../../../../../../services/general';
import ErrorService from '../../../../../../services/general/error';

// props
import { IVendorServiceResourceShortProps } from '../../../../../../props/data/vendors/service';

// components
import { ActionButton, DefaultButton, DetailsList, IColumn, Panel, PanelType, PrimaryButton, SelectionMode, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import PermissionsService from '../../../../../../services/permissions';
import NoAccess from '../../../../../uiframeworks/noAccess';
import SelectArea from '../../../../../uiframeworks/forms/data/geographics/area';
import SelectService from '../../../../../uiframeworks/forms/data/service';
import SelectShipType from '../../../../../uiframeworks/forms/data/fleets/shipType';
import Label from '../../../../../typography/label';
import { IVendorFleetResourceShortProps } from '../../../../../../props/data/vendors/fleet';
import SelectTruckType from '../../../../../uiframeworks/forms/data/fleets/truckType';
import { IDataShipTypeResourceShortProps } from '../../../../../../props/data/fleets/shipType';
import { IDataTruckTypeResourceShortProps } from '../../../../../../props/data/truckType';
import DataShipTypeService from '../../../../../../services/data/fleets/shipType';
import DataTruckTypeService from '../../../../../../services/data/fleets/truckType';

type VendorServiceItemFormProps = {
    service?: IVendorServiceResourceShortProps;
    onDismissed(refresh?: boolean): void;
}

const VendorServiceItemForm: React.FC<VendorServiceItemFormProps> = (props: VendorServiceItemFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [data, setData] = React.useState<Partial<IVendorServiceResourceShortProps>>(props.service || { id: GeneralService.guid(), fleets: [{ id: GeneralService.guid(), quantity: "1" }] });
    const [service, setService] = React.useState<string | undefined>();
    const [error, setError] = React.useState<any>({});

    const [shipTypes, setShipTypes] = React.useState<IDataShipTypeResourceShortProps[]>([]);
    const [truckTypes, setTruckTypes] = React.useState<IDataTruckTypeResourceShortProps[]>([]);

    let hasPermission = PermissionsService.hasPermission(['vendors.update.all'], user.permissions);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _shipTypes = await DataShipTypeService.retrieve();
        const _truckTypes = await DataTruckTypeService.retrieve();

        setShipTypes(_shipTypes);
        setTruckTypes(_truckTypes);

        setLoaded(true);
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
        } catch (e) {
            banner.add({
                key: 'update_vendor_services_error',
                variant: 'error',
                icon: faClose,
                text: ErrorService.getMessage(e)
            });
            setSubmitting(false);
        }
    }

    const columns: IColumn[] = [
        {
            key: 'fleet',
            name: 'Fleet',
            minWidth: 100,
            onRender: (item: IVendorFleetResourceShortProps) => {
                let selectField: JSX.Element | null = null;
                if (service === 'shipping') {
                    selectField = <SelectShipType hideLabel
                        key={item.id}
                        selected={item.ship}
                        options={shipTypes}
                        onChange={(selected) => {
                            const _data = data;
                            const idx = (_data.fleets || []).findIndex(f => f.id === item.id);
                            if (_data.fleets && idx > -1) {
                                _data.fleets[idx].ship = selected;
                            }

                            if (selected && _data.fleets) {
                                _data.fleets.push({ id: GeneralService.guid(), quantity: "1" });
                            }

                            setData({ ..._data });
                        }} />
                } else if (service === 'trucking') {
                    selectField = <SelectTruckType hideLabel
                        key={item.id}
                        selected={item.truck}
                        options={truckTypes}
                        onChange={(selected) => {
                            const _data = data;
                            const idx = (_data.fleets || []).findIndex(f => f.id === item.id);
                            if (_data.fleets && idx > -1) {
                                _data.fleets[idx].truck = selected;
                            }

                            if (selected && _data.fleets) {
                                _data.fleets.push({ id: GeneralService.guid(), quantity: "1" });
                            }

                            setData({ ..._data });
                        }} />
                } else if (service === 'warehousing') {

                }

                return selectField;
            }
        },
        {
            key: 'size',
            name: 'Size',
            minWidth: 125,
            maxWidth: 125,
            onRender: (item: IVendorFleetResourceShortProps) => {
                return <TextField value={item.size || ""}
                    suffix={'tonage'}
                    onChange={(evt, value) => {
                        const _data = data;

                        if ((value || "").trim() === "" || !isNaN(Number(value))) {
                            const idx = (_data.fleets || []).findIndex(f => f.id === item.id);
                            if (_data.fleets && idx > -1) {
                                _data.fleets[idx].size = value || "";
                            }
                        }

                        setData({ ..._data });
                    }} />;
            }
        },
        {
            key: 'quantity',
            name: 'Quantity',
            minWidth: 75,
            maxWidth: 75,
            onRender: (item: IVendorFleetResourceShortProps) => {
                return <TextField value={item.quantity || ""}
                    onChange={(evt, value) => {
                        const _data = data;

                        if ((value || "").trim() === "" || !isNaN(Number(value))) {
                            const idx = (_data.fleets || []).findIndex(f => f.id === item.id);
                            if (_data.fleets && idx > -1) {
                                _data.fleets[idx].quantity = value || "";
                            }
                        }

                        setData({ ..._data });
                    }} />;
            }
        },
        {
            key: 'action',
            name: '',
            minWidth: 38,
            maxWidth: 38,
            onRender: (item: IVendorFleetResourceShortProps) => {
                if (item.ship || item.truck) {
                    return <ActionButton disabled={(data.fleets || []).length < 2}
                        iconProps={{ iconName: "Delete" }}
                        onClick={() => {
                            const _data = data;
                            _data.fleets = (_data.fleets || []).filter(f => f.id !== item.id);
                            console.log(_data.fleets);

                            setData({ ..._data });
                        }} />;
                }
                return null;
            }
        }
    ]

    return <Panel headerText={props.service ? "Update Service Area" : 'Add Service Area'}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting && hasPermission ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={false} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Updating vendor ..."} /> : null}
            </Stack>;
        }}>
        <Stack>
            {!loaded ? <Stack horizontalAlign={"baseline"} styles={{ root: { marginTop: 20 } }}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && hasPermission ? <>
                    <Stack tokens={{ childrenGap: 20 }}>
                        <SelectService label={'Service'}
                            selected={data.service}
                            onChange={(selected) => {
                                const _data = data;
                                _data.service = selected;

                                setData({ ..._data });
                                setService(selected ? selected.name.toLowerCase() : undefined);
                            }} />
                        <SelectArea label='Areas of service'
                            selected={{ countries: data.countries, provinces: data.provinces, cities: data.cities }}
                            onChange={(selected) => {
                                setData({
                                    ...data,
                                    countries: selected.countries,
                                    provinces: selected.provinces,
                                    cities: selected.cities
                                });
                            }} />
                        {data.service ? <>
                            <Stack className={'divider'}></Stack>
                            <Stack>
                                <Label size={'xsmall'}>Fleets directory</Label>
                                <DetailsList items={data.fleets || []}
                                    columns={columns}
                                    selectionMode={SelectionMode.none}
                                    compact />
                            </Stack>
                        </> : null}
                    </Stack>
                </> : null
            }
            {!hasPermission ? <NoAccess /> : null}
        </Stack>
    </Panel>
};

export default VendorServiceItemForm;
