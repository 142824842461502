import React, { useEffect } from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { useStore } from '../../../../stores/root';

// assets
import { faArrowRight, faHome, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import ErrorService from '../../../../services/general/error';
import OrderService from '../../../../services/orders/order';

// props
import { IOrderResourceProps } from '../../../../props/orders/order';
import { IOrderSummaryResourceShortProps } from '../../../../props/orders/summary';

// components
import { ActionButton, CommandBar, IButtonProps, ICommandBarItemProps, PrimaryButton, Stack } from '@fluentui/react';
import Text from '../../../typography/text';
import LoadingComponent from '../../../feedbacks/loading';
import Label from '../../../typography/label';
import DetailsStack, { ISectionTabProps, IStackSectionProps } from '../../../uiframeworks/detailsStack';
import Tag, { TTagVariant } from '../../../uiframeworks/tag';
import DeliveredGoodsType from '../../../../manifests/deliveredGoodsType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrderForm from './../form';
import OrderContainersList from '../../containers/general/list';
import OrderAdditionalChargesList from '../../additionalCharges/general/list';
import CancelOrderForm from '../cancel';
import ActivateOrderForm from '../activate';
import UpdateOrderStatusForm from '../status';
import OrderTrackingsList from '../../trackings/general/list';
import OrderTrucksList from '../../trucks/general/list';
import OrderOutcomesList from '../../outcomes/general/list';
import OrderDocumentsList from '../../documents/general/list';
import OrderGoodsList from '../../goods/general/list';
import OrderSalesTeamForm from '../form/salesTeam';
import OrderSalesForm from '../form/sales';
import OrderDetailsProfitSummary from './subdetails/profitSummary';
import OrderDetailsPriceSummary from './subdetails/priceSummary';
import OrderDetailsSales from './subdetails/sales';
import PermissionsService from '../../../../services/permissions';
import Permissions from '../../../../permissions';
import { observer } from 'mobx-react';
import OrderVersionHistory from '../versions';
import NoAccess from '../../../uiframeworks/noAccess';
import IncomeInvoicesList from '../../../finances/incomes/invoices/general/list';

interface IOrderDetailsProps {
    orderId: string;
}

const OrderDetails: React.FC<IOrderDetailsProps> = observer((props: IOrderDetailsProps) => {
    const { banner, topbar, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [order, setOrder] = React.useState<IOrderResourceProps>();
    const [summary, setSummary] = React.useState<IOrderSummaryResourceShortProps>();
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [hasPermission, setHasPermission] = React.useState<boolean>(false);

    useEffect(() => {
        _onGetOrder();
    }, []);

    const _onGetOrder = async () => {
        try {
            const _order = await OrderService.get(props.orderId);
            const orderNumber = OrderService.getOrderNumber(_order.orderNumber);
            setOrder(_order);

            const _summary = await OrderService.summary.get(props.orderId);
            setSummary(_summary);

            let _hasPermission = PermissionsService.hasPermission(['orders.read.all'], user.permissions) || (PermissionsService.hasPermission(['orders.read.me'], user.permissions) && OrderService.isMine(_order, user.data));
            setHasPermission(_hasPermission);

            topbar.show("Order #" + orderNumber, [
                { key: "home", icon: faHome, href: "/" },
                { key: "orders", text: 'Orders', href: "/orders" },
                { key: props.orderId, text: "#" + orderNumber }
            ]);

            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'get_order_error',
                text: 'Failed to get order details. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const getCommandBarItems = () => {
        const items: ICommandBarItemProps[] = [];
        const farItems: ICommandBarItemProps[] = [];

        if (order && order.status.key !== 'cancelled') {
            if (PermissionsService.hasPermission(['orders.update.all'], user.permissions)) {
                items.push({
                    key: 'update',
                    text: 'Update details',
                    onRender: () => <PrimaryButton text={'Update Details'} onClick={() => setActiveSurface('update')} />
                });
            }

            if (PermissionsService.hasPermission(['orders.update.all'], user.permissions)) {
                items.push({
                    key: 'update',
                    text: 'Update status',
                    onRender: () => <ActionButton text={'Update Status'} onClick={() => setActiveSurface('updateStatus')} styles={{ root: { marginLeft: 20 } }} />
                });
            }

            if (PermissionsService.hasPermission(['orders.cancel.all'], user.permissions)) {
                farItems.push({
                    key: 'cancel',
                    text: 'Cancel Order',
                    onRender: () => <ActionButton text={'Cancel Order'} iconProps={{ iconName: "Xmark" }} onClick={() => setActiveSurface('cancel')} />
                });
            }
        } else {
            items.push({
                key: 'update',
                text: 'Re-activate details',
                onRender: () => <PrimaryButton text={'Re-activate Order'} onClick={() => setActiveSurface('activate')} />
            });
        }

        if (order?.offer) {
            items.push({
                key: 'viewOffer',
                text: 'View offer',
                onRender: () => <ActionButton text={'View offer'} href={'/offers/' + order.offer?.id} target='_blank' />
            });
        }

        items.push({
            key: 'versionHistory',
            text: 'Version history',
            onRender: () => <ActionButton text={'Version history'} onClick={() => setActiveSurface('versionHistory')} />
        });

        return { items, farItems };
    }

    const getAddressSection = (_order: IOrderResourceProps): IStackSectionProps => {
        const origin = OrderService.getAddress(_order.originAddresses[0].type, _order.originAddresses[0].address, _order.originAddresses[0].city, _order.originAddresses[0].province);
        const destination = OrderService.getAddress(_order.destinationAddresses[0].type, _order.destinationAddresses[0].address, _order.destinationAddresses[0].city, _order.destinationAddresses[0].province);

        return {
            header: {
                title: "Address"
            },
            key: 'order_address',
            isCard: true,
            content: <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack styles={{ root: { width: '50%' } }}>
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Label size={'small'}>Pick-up Address:</Label>
                        {_order.originAddresses.length > 1 ? <ol style={{ padding: '0px 12px', margin: 0 }}>
                            {_order.originAddresses.map((address) => {
                                const text = OrderService.getAddress(address.type, address.address, address.city, address.province);
                                return <Text size={'small'}><li>{text}</li></Text>
                            })}
                        </ol> : null}
                        {_order.originAddresses.length < 2 && _order.originAddresses.length > 0 ? <Text size={'small'}>{origin}</Text> : null}
                    </Stack>
                </Stack>
                <Stack styles={{ root: { marginTop: 35 } }}><FontAwesomeIcon icon={faArrowRight} /></Stack>
                <Stack styles={{ root: { width: '50%' } }}>
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Label size={'small'}>Delivery Address:</Label>
                        {_order.destinationAddresses.length > 1 ? <ol style={{ padding: '0px 12px', marginBottom: 0 }}>
                            {_order.destinationAddresses.map((address) => {
                                const text = OrderService.getAddress(address.type, address.address, address.city, address.province);
                                return <Text size={'small'}><li>{text}</li></Text>
                            })}
                        </ol> : null}
                        {_order.destinationAddresses.length < 2 && _order.destinationAddresses.length > 0 ? <Text size={'small'}>{destination}</Text> : null}
                    </Stack>
                </Stack>
            </Stack>
        }
    }

    const getPropertiesSection = (_order: IOrderResourceProps): IStackSectionProps => {
        const goodsType = DeliveredGoodsType.find((dgt) => dgt.key === _order.goodsType);

        let statusVariant: TTagVariant = 'active';
        if (_order.status.key === 'completed') { statusVariant = 'success'; }
        else if (_order.status.key === 'cancelled') { statusVariant = 'error'; }
        else if (_order.status.key === 'active') { statusVariant = 'active'; }

        let paidText: string = "Unpaid";
        let paidVariant: TTagVariant = 'error';
        if (_order.paymentCompleted) {
            paidVariant = 'success';
            paidText = 'Paid'
        }

        return {
            header: {
                title: "Details"
            },
            key: 'order_details',
            isCard: true,
            content: <Stack tokens={{ childrenGap: 20 }}>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: '33.3%' } }}>
                        <Label size={'small'}>Order number</Label>
                        <Text>#{OrderService.getOrderNumber(_order.orderNumber)}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '33.3%' } }}>
                        <Label size={'small'}>Status</Label>
                        <Stack horizontal><Tag variant={statusVariant} text={_order.status.name} /></Stack>
                    </Stack>
                    <Stack styles={{ root: { width: '33.3%' } }}>
                        <Label size={'small'}>Paid / Unpaid</Label>
                        {Number(_order.totalAmount) > 0 ? <Stack horizontal><Tag variant={paidVariant} text={paidText} /></Stack> : <Text>-</Text>}
                    </Stack>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: '33.3%' } }}>
                        <Label size={'small'}>Create date</Label>
                        <Text>{moment(_order.createdAt).format("DD/MM/YYYY")}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '33.3%' } }}>
                        <Label size={'small'}>Batch</Label>
                        <Text>{_order.batch || "-"}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '33.3%' } }}>
                        <Label size={'small'}>Order type</Label>
                        <Text>{_order.freightCategory.name} - {_order.deliveryType.name} - {goodsType?.text}</Text>
                    </Stack>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack styles={{ root: { width: 'calc(33.3% - 15px)' } }}>
                        <Label size={'small'}>Company</Label>
                        {_order.company ? <Stack horizontal><NavLink to={"/users/companies/" + _order.company.id}><Text>{_order.company.name}</Text></NavLink></Stack> : null}
                        {!_order.company ? <Text>-</Text> : null}
                    </Stack>
                    <Stack styles={{ root: { width: 'calc(33.3% - 15px)' } }}>
                        <Label size={'small'}>Customer</Label>
                        <Stack horizontal><NavLink to={"/users/customers/" + _order.customer.id}><Text>{_order.customer.name}</Text></NavLink></Stack>
                    </Stack>
                    {/*<Stack styles={{ root: { width: '33.3%' } }}>
                        <Label size={'small'}>Sales</Label>
                        {_order.sales ? <Stack horizontal><NavLink to={"/users/administrators/" + _order.sales.id}><Text>{_order.sales.name}</Text></NavLink></Stack> : null}
                        {!_order.sales ? <Text>-</Text> : null}
                    </Stack>*/}
                </Stack>
            </Stack>
        }
    }

    const getSubsidiarySection = (_order: IOrderResourceProps): IStackSectionProps => {
        return {
            header: {
                title: "Subsidiary"
            },
            key: 'subsidiary',
            isCard: true,
            content: <Stack tokens={{ childrenGap: 10 }}>
                <Stack horizontal horizontalAlign={'space-between'}>
                    {_order.subsidiary ? <Text>{_order.subsidiary.name}</Text> : null}
                    {!_order.subsidiary ? <Text style={{ fontStyle: 'italic' }}>No subsidiary selected</Text> : null}
                </Stack>
            </Stack>
        }
    }

    /*const getSalesTeamSection = (_order: IOrderResourceProps): IStackSectionProps => {
        return {
            header: {
                title: "Sales Team",
                buttons: [
                    {
                        iconProps: { iconName: "Edit" },
                        onClick: () => {
                            setActiveSurface('salesteam.update');
                        }
                    }
                ]
            },
            key: 'salesteam',
            isCard: true,
            content: <Stack tokens={{ childrenGap: 10 }}>
                <Stack horizontal horizontalAlign={'space-between'}>
                    {_order.salesTeam ? <Text>{_order.salesTeam.name}</Text> : null}
                    {!_order.salesTeam ? <Text style={{ fontStyle: 'italic' }}>No sales team selected</Text> : null}
                </Stack>
            </Stack>
        }
    }*/

    const getSalesSection = (_order: IOrderResourceProps): IStackSectionProps => {
        const buttons: IButtonProps[] = [];
        if (PermissionsService.hasPermission(Permissions.order.sales.update, user.roles)) {
            buttons.push({
                iconProps: { iconName: "Edit" },
                onClick: () => {
                    setActiveSurface('sales.update');
                }
            });
        }

        return {
            header: {
                title: "Sales"
            },
            key: 'sales',
            isCard: true,
            content: <OrderDetailsSales order={_order} />
        }
    }

    const getPriceSummarySection = (_order: IOrderResourceProps, _summary: IOrderSummaryResourceShortProps): IStackSectionProps => {
        const subsections: IStackSectionProps[] = [];
        subsections.push({

            header: {
                title: "Price"
            },
            key: 'price',
            isCard: true,
            content: <OrderDetailsPriceSummary order={_order} />
        });

        if (PermissionsService.hasPermission(['orders.expenses.read.all'], user.permissions) ||
            (PermissionsService.hasPermission(['orders.expenses.read.me'], user.permissions) && OrderService.isMine(_order, user.data))) {
            subsections.push({
                header: {
                    title: "Summary"
                },
                key: 'summary',
                isCard: true,
                content: <OrderDetailsProfitSummary order={_order} summary={_summary} />
            });
        }

        return {
            key: 'pricesummary',
            subsections
        }
    }

    const getTabsSection = (_order: IOrderResourceProps): IStackSectionProps => {
        const tabs: ISectionTabProps[] = [];
        if (_order.goodsType === 'ftl' || _order.goodsType === 'ltl') {
            tabs.push({
                key: 'trucks_list',
                title: 'Truck(s)',
                content: <Stack>
                    <OrderTrucksList orderId={_order.id} variant={'plain'} />
                </Stack>
            });
        }

        if (_order.goodsType === 'fcl' || _order.goodsType === 'lcl') {
            tabs.push({
                key: 'cotnainers_list',
                title: 'Container(s)',
                content: <Stack>
                    <OrderContainersList orderId={_order.id} variant={'plain'} />
                </Stack>
            });
        }

        if (_order.goodsType === 'bulk') {
            tabs.push({
                key: 'goods_list',
                title: 'Goods',
                content: <Stack>
                    <OrderGoodsList orderId={_order.id} variant={'plain'} />
                </Stack>
            });
        }

        tabs.push({
            key: 'additional_charges_list',
            title: 'Additional Charge(s)',
            content: <Stack>
                <OrderAdditionalChargesList orderId={_order.id} variant={'plain'} />
            </Stack>
        });

        tabs.push({
            key: 'trackings',
            title: 'Trackings',
            content: <Stack>
                <OrderTrackingsList orderId={_order.id} variant={'plain'} />
            </Stack>
        });

        tabs.push({
            key: 'related_documents',
            title: 'Related Document(s)',
            content: <Stack>
                <OrderDocumentsList orderId={_order.id} variant='plain' />
            </Stack>
        });

        if (PermissionsService.hasPermission(['orders.expenses.read.all'], user.permissions) ||
            (PermissionsService.hasPermission(['orders.expenses.read.me'], user.permissions) && OrderService.isMine(_order, user.data))) {
            tabs.push({
                key: 'expenses',
                title: 'Expense(s)',
                content: <Stack>
                    <OrderOutcomesList orderId={_order.id} variant={'plain'} hideCreateButton={true} />
                </Stack>
            });
        }

        tabs.push({
            key: 'invoices',
            title: 'Invoice(s)',
            content: <Stack>
                <IncomeInvoicesList orderId={_order.id} 
                    columns={['amount', 'dueDate', 'invoiceNumber', 'notes', 'paid', 'status', 'unpaid']}
                    variant={'plain'} 
                    qs={['order_id=' + _order.id]} 
                    hideSearch={true}
                    hideCommandBar={order && order.invoices.length > 0}
                    hideCreateButton={order && order.invoices.length > 0} />
            </Stack>
        });

        return {
            key: 'order_tabs',
            isCard: true,
            tabs
        }
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        if (refresh) { _onGetOrder() }
    }

    return <Stack tokens={{ childrenGap: 20 }}>
        {!loaded ? <LoadingComponent label='Retrieving order details ...' labelPosition='right' spinnerPosition='baseline' /> : null}
        {loaded && hasPermission && order && summary ? <>
            <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" />
            <Stack tokens={{ childrenGap: 20 }}>
                <DetailsStack left={[getAddressSection(order), getPropertiesSection(order), getPriceSummarySection(order, summary)]} right={[getSubsidiarySection(order), getSalesSection(order)]} />
                <DetailsStack left={[getTabsSection(order)]} />
            </Stack>
            {activeSurface === 'update' ? <OrderForm orderId={order.id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'cancel' ? <CancelOrderForm order={order} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'activate' ? <ActivateOrderForm order={order} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'updateStatus' ? <UpdateOrderStatusForm order={order} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'salesteam.update' ? <OrderSalesTeamForm order={order} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'sales.update' ? <OrderSalesForm order={order} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'versionHistory' ? <OrderVersionHistory orderId={order.id} onDismissed={_onSurfaceDismissed} /> : null}
        </> : null}
        {loaded && !hasPermission ? <NoAccess /> : null}
    </Stack>;
});

export default OrderDetails;
