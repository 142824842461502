import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useStore } from '../../../../../../stores/root';
import moment from 'moment';
import GeneralService from '../../../../../../services/general';
import OutcomePaymentService from '../../../../../../services/finance/outcomes/payments';
import { DatePicker, DefaultButton, DetailsList, MessageBarType, Panel, PanelType, Pivot, PivotItem, PrimaryButton, SelectionMode, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../../../typography/label';
import Text from '../../../../../typography/text';
import { IOutcomePaymentResourceProps, IOutcomePaymentResourceShortProps } from '../../../../../../props/finance/outcomes/payments';
import PaymentMethods from '../../../../../../manifests/paymentMethods';
import PermissionsService from '../../../../../../services/permissions';
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import ErrorService from '../../../../../../services/general/error';
import LoadingComponent from '../../../../../feedbacks/loading';
import { IInternalBankAccountResourceShortProps } from '../../../../../../props/data/bankaccounts/internal';
import FileService from '../../../../../../services/general/file';
import OpenAIService from '../../../../../../services/systems/openai';
import LoadingDialogComponent from '../../../../../feedbacks/loadingDialog';
import SimpleMessageBarComponent from '../../../../../feedbacks/simpleMessageBar';
import SelectBankAccount from '../../../../../uiframeworks/forms/bankaccounts';
import { IBankAccountResourceShortProps } from '../../../../../../props/bankaccounts';

interface OutcomePaymentCompleteFormProps {
  payments: IOutcomePaymentResourceShortProps[];
  onDismissed(refresh?: boolean): void;
}

type FormDataProps = {
  paymentDate: string;
  transferFrom?: IBankAccountResourceShortProps;
  transferTo?: IBankAccountResourceShortProps;
}

const OutcomePaymentCompleteForm: React.FC<OutcomePaymentCompleteFormProps> = (props: OutcomePaymentCompleteFormProps) => {
  const { banner, user } = useStore();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [file, setFile] = useState<File | undefined>();
  const [preview, setPreview] = React.useState<string[]>();
  const [extracting, setExtracting] = React.useState<boolean>(false);
  const [warnings, setWarnings] = React.useState<string[]>([]);
  const [data, setData] = useState<FormDataProps>({
    paymentDate: moment().toISOString(),
    transferFrom: props.payments[0].transferFrom,
    transferTo: props.payments[0].transferTo
  });

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [payments, setPayments] = React.useState<IOutcomePaymentResourceProps[]>([]);
  const paymentMethod = PaymentMethods.find((m) => m.key === props.payments[0].method);

  const hasPermission = PermissionsService.hasPermission(['outcomes.invoices.payments.approve'], user.permissions);

  const _onSubmit = async () => {
    setSubmitting(true)
    const fd = new FormData();

    payments.forEach((payment) => {
      fd.append('ids[]', payment.id);
    });

    fd.append('totalAmount', getTotalAmount() + "");
    fd.append('paymentDate', data.paymentDate);
    fd.append('transferFromId', data.transferFrom?.id || "");
    fd.append('transferToId', data.transferTo?.id || "");

    if (file) {
      fd.append('metadatas[]', JSON.stringify({ visibility: 'Public', name: file.name, type: "Proof of Payment" }));
      fd.append('documents[]', file);
    }

    try {
      await OutcomePaymentService.setComplete(fd);
      props.onDismissed(true)
    } catch (error) {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    _onRetrievePayment();
  }, []);

  const _onRetrievePayment = async () => {
    try {
      setLoaded(false);
      setPayments(props.payments);
      setLoaded(true);
    } catch (e) {
      banner.add({
        key: 'get_payment_error',
        text: 'Failed to retrieve payments. Error: ' + ErrorService.getMessage(e),
        icon: faXmarkCircle,
        variant: 'error'
      });
    }
  }

  const getTotalAmount = () => {
    let totalAmount = 0;
    payments.forEach((payment) => {
      totalAmount += Number(payment.amount);
    });

    return totalAmount;
  }

  const extract = async (file: File): Promise<{
    amount: number;
    notes?: string;
    paymentDate: string;
  } | undefined> => {
    try {
      const images = await FileService.getBase64(file);

      const fd = new FormData();
      fd.append('type', 'proofOfPayment');
      images.forEach((img) => fd.append("base64[]", img));

      const result = await OpenAIService.extractDocument(fd);
      return result;
    } catch (e) {
      return;
    }
  }

  const _onFileChange = async (_files: FileList | File[]) => {
    try {
      if (file) {
        if (!window.confirm("Are you sure you want to replace current proof of payment file?")) {
          return;
        }
      }

      const _file = _files[0];
      setFile(_file);

      const _warnings: string[] = [];
      if (_file) {
        const images = await FileService.getBase64(_file);
        setPreview(images);

        setExtracting(true);
        const result = await extract(_file);
        if (result && result.amount !== getTotalAmount()) {
          _warnings.push("Total amount does not matched the amount that is transfered");
        }
        if (result && moment(result.paymentDate).format("DD/MM/YYYY") !== moment(data.paymentDate).format("DD/MM/YYYY")) {
          _warnings.push("Payment date does not matched the transfer date from proof of payment. Make sure the payment date is correct.");
        }
      } else {
        _warnings.push("No proof of payment is uploaded");
      }

      setWarnings(_warnings);
      setExtracting(false);
    } catch (e) {
      setWarnings([]);
      setExtracting(false);
      setFile(undefined);
    }
  }

  return <Panel
    headerText={'Complete Payment'}
    isOpen={true}
    type={PanelType.large}
    onDismiss={() => props.onDismissed(false)}
    isFooterAtBottom={true}
    onRenderFooterContent={() => {
      return <Stack horizontal tokens={{ childrenGap: 10 }}>
        {
          !submitting && hasPermission ? (
            <>
              <PrimaryButton disabled={!file} text={"Submit"} onClick={_onSubmit} />
              <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
            </>
          ) : null
        }
        {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Recording payment ..."} /> : null}
      </Stack>;
    }}
  >
    {hasPermission ? <Stack tokens={{ childrenGap: 15 }}>
      {!loaded ? <LoadingComponent label={'Retrieving form ...'} labelPosition={'right'} spinnerPosition={'baseline'} /> : null}
      {warnings.length > 0 ? <Stack tokens={{ childrenGap: 5 }}>
        {warnings.map((warning) => <SimpleMessageBarComponent properties={{ type: MessageBarType.warning, content: <Text size={'small'}>{warning}</Text> }} />)}
      </Stack> : null}
      {loaded ? <Stack horizontal tokens={{ childrenGap: 20 }}>
        <Stack grow={1} styles={{ root: { width: '50%' } }} tokens={{ childrenGap: 10 }}>
          <input type={'file'} onChange={(evt) => _onFileChange(evt?.target.files || [])} value={[]} />
          <Stack className={styles.preview}>
            {extracting ? <LoadingComponent label={'Generating file preview ...'} size={SpinnerSize.medium} /> : null}
            {preview && !extracting ? preview.map((preview) => {
              return <img src={preview} className={styles.image} />;
            }) : null}
            {!preview && !extracting ? <Text size={'small'} className={'color-white'}>No proof of payment selected</Text> : null}
          </Stack>
        </Stack>
        {extracting ? <LoadingDialogComponent title='Extracting Proof of Payment' secondaryText="Please wait while we extract proof of payment details ..." /> : null}
        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 700 } }}>
          <Pivot className={'linePivot'}>
            <PivotItem key={'details'} headerText='Details'>
              <Stack tokens={{ childrenGap: 15 }}>
                <Stack styles={{ root: { marginTop: 15 } }}>
                  <Label size={'xsmall'}>Related payments</Label>
                  <DetailsList items={payments || []}
                    selectionMode={SelectionMode.none}
                    columns={[
                      {
                        key: 'name',
                        name: 'Invoice Details',
                        minWidth: 150,
                        maxWidth: 150,
                        isMultiline: true,
                        onRender: (item: IOutcomePaymentResourceShortProps, idx?: number) => {
                          return <Stack styles={{ root: { paddingTop: 8 } }}>
                            <Label size={'xsmall'}>{item.invoice.name}</Label>
                            {(item.invoice.invoiceNumber || "").trim() !== "" ? <Text size={'small'}>#{item.invoice.invoiceNumber}</Text> : null}
                            {item.invoice.vendor ? <Text size={'small'}>{item.invoice.vendor.name}</Text> : null}
                          </Stack>;
                        }
                      },
                      {
                        key: 'notes',
                        name: 'Notes',
                        minWidth: 0,
                        isMultiline: true,
                        onRender: (item: IOutcomePaymentResourceShortProps, idx?: number) => {
                          return <Stack styles={{ root: { paddingTop: 8 } }}>
                            <Text size={'small'}>{item.notes || "-"}</Text>
                          </Stack>;
                        }
                      },
                      {
                        key: 'amount',
                        name: 'Payment Amount',
                        minWidth: 125,
                        maxWidth: 125,
                        isMultiline: true,
                        onRender: (item: IOutcomePaymentResourceShortProps, idx?: number) => {
                          return <Stack styles={{ root: { paddingTop: 8 } }}>
                            <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.amount || '0'))}</Text>
                          </Stack>;
                        }
                      }
                    ]} />
                </Stack>
                <Stack>
                  <Label size={'xsmall'}>Total amount</Label>
                  <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(getTotalAmount())}</Text>
                </Stack>
                <Stack className={'divider'} />
                <Label size={'small'}>Source Payment Details</Label>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                  <Stack styles={{ root: { width: '50%' } }}>
                    <DatePicker label={'Payment date'}
                      value={moment(data.paymentDate).toDate()}
                      formatDate={GeneralService.formatDate}
                      onSelectDate={(date) => {
                        if (date != undefined) {
                          const _data = data;
                          _data.paymentDate = moment(date).toISOString();

                          setData({ ..._data });
                        }
                      }} />
                  </Stack>
                  <Stack styles={{ root: { width: '50%' } }}>
                    <SelectBankAccount label={"Transfer from"}
                      selected={data.transferFrom}
                      subsidiaryId={data.transferFrom?.subsidiaryId}
                      onChange={(bankaccount) => {
                        const _data = data;
                        _data.transferFrom = bankaccount;

                        setData({ ..._data });
                      }} />
                  </Stack>
                </Stack>
                <Stack className={'divider'} />
                <Label size={'small'}>Target Payment Details</Label>
                {payments[0].invoice?.vendor ? <Stack>
                  <Label size={'xsmall'}>Vendor</Label>
                  <Text size={'small'}>{payments[0].invoice?.vendor?.name || "-"}</Text>
                </Stack> : null}
                {payments[0].invoice?.targetSubsidiary ? <Stack>
                  <Label size={'xsmall'}>Target subsidiary</Label>
                  <Text size={'small'}>{payments[0].invoice?.targetSubsidiary?.name || "-"}</Text>
                </Stack> : null}
                {payments[0].invoice?.targetEmployee ? <Stack>
                  <Label size={'xsmall'}>Target employee</Label>
                  <Text size={'small'}>{payments[0].invoice?.targetEmployee?.name || "-"}</Text>
                </Stack> : null}
                <Stack>
                  <Label size={'xsmall'}>Method</Label>
                  <Text size={'small'}>{paymentMethod?.text || "Cash"}</Text>
                </Stack>
                {payments[0].method === 'transfer' && payments[0].transferTo ? <Stack horizontal>
                <Stack styles={{ root: { width: '33.3%' } }}>
                    <Label size={'xsmall'}>Account Name</Label>
                    <Text size={'small'}>{payments[0].transferTo.accountName}</Text>
                  </Stack>
                  <Stack styles={{ root: { width: '33.3%' } }}>
                    <Label size={'xsmall'}>Account Number</Label>
                    <Text size={'small'}>{payments[0].transferTo.accountNumber}</Text>
                  </Stack>
                  <Stack styles={{ root: { width: '33.3%' } }}>
                    <Label size={'xsmall'}>Bank Details</Label>
                    <Text size={'small'}>{payments[0].transferTo.bank.name} ({payments[0].transferTo.bankCity})</Text>
                  </Stack>
                </Stack> : null}
                {payments[0].method === 'virtualaccount' && props.payments[0].transferToVANumber ? <Stack>
                  <Label size={'xsmall'}>Virtual Account Number</Label>
                  <Text size={'small'}>{payments[0].transferToVANumber}</Text>
                </Stack> : null}
              </Stack>
            </PivotItem>
          </Pivot>
        </Stack>
      </Stack> : null}
    </Stack> : null}
  </Panel>
};

export default OutcomePaymentCompleteForm;
