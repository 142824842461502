import React, { useEffect } from 'react';
import { useStore } from '../../../../../stores/root';

// assets
import { faCheck, faClose } from '@fortawesome/pro-light-svg-icons';

// services
import ValidationService from '../../../../../services/validation';
import VendorsService from '../../../../../services/data/vendors';

// props
import { IRoleResourceShortProps } from '../../../../../props/users/role';

// components
import { DefaultButton, Panel, PanelType, Pivot, PivotItem, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import PermissionsService from '../../../../../services/permissions';
import ErrorService from '../../../../../services/general/error';
import NoAccess from '../../../../uiframeworks/noAccess';
import Text from '../../../../typography/text';
import { IVendorResourceProps } from '../../../../../props/data/vendors';
import VendorDocumentsForm from './documents';
import VendorDetailsForm from './details';
import VendorPICForm from './pic';
import Label from '../../../../typography/label';
import { IDocumentProps, IFileProps } from '../../../../../props/general';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type VerifyVendorFormProps = {
    vendorId: string;
    onDismissed(refresh?: boolean, vendor?: IVendorResourceProps): void;
}

const VerifyVendorForm: React.FC<VerifyVendorFormProps> = (props: VerifyVendorFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [vendor, setVendor] = React.useState<IVendorResourceProps | undefined>();

    let hasPermission = PermissionsService.hasPermission(['vendors.verification.verify'], user.permissions);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _vendor = await VendorsService.get(props.vendorId);

        setVendor(_vendor);
        setLoaded(true);
    }

    const _onSubmit = async () => {
        try {
            if (window.confirm("Are you sure to set this vendor as verified?")) {
                setSubmitting(true);
                await VendorsService.verify(props.vendorId);

                banner.add({
                    key: 'verifiy_vendor_success',
                    variant: 'success',
                    icon: faCheck,
                    text: `Vendor "${vendor?.name}" is now verified`
                });
                props.onDismissed(true);
            }
        } catch (e) {
            banner.add({
                key: 'verify_vendor_error',
                variant: 'error',
                icon: faClose,
                text: ErrorService.getMessage(e)
            });
            setSubmitting(false);
        }
    }

    const renderRelatedDocuments = (documents: IDocumentProps[]) => {
        const npwpDocument = documents.find((d) => (d.type || "").toLowerCase() === 'npwp');
        const nibDocument = documents.find((d) => (d.type || "").toLowerCase() === 'nib');
        const ownerIdDocument = documents.find((d) => (d.type || "").toLowerCase() === 'owner id');

        return <Stack tokens={{ childrenGap: 10 }}>
            <Label size={"small"}>Documents</Label>
            <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign={'center'}>
                <FontAwesomeIcon fontSize={14} icon={(npwpDocument ? faCheckCircle : faXmarkCircle) as IconProp} style={{ marginTop: -3 }} className={npwpDocument ? 'color-green' : 'color-red'} />
                <Text size={'small'} className={npwpDocument ? 'color-green' : 'color-red'}>{npwpDocument ? "NPWP document found" : "No NPWP document found"}</Text>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign={'center'}>
                <FontAwesomeIcon fontSize={14} icon={(nibDocument ? faCheckCircle : faXmarkCircle) as IconProp} style={{ marginTop: -3 }} className={nibDocument ? 'color-green' : 'color-red'} />
                <Text size={'small'} className={nibDocument ? 'color-green' : 'color-red'}>{nibDocument ? "NIB document found" : "No NIB document found"}</Text>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign={'center'}>
                <FontAwesomeIcon fontSize={14} icon={(ownerIdDocument ? faCheckCircle : faXmarkCircle) as IconProp} style={{ marginTop: -3 }} className={ownerIdDocument ? 'color-green' : 'color-red'} />
                <Text size={'small'} className={ownerIdDocument ? 'color-green' : 'color-red'}>{ownerIdDocument ? "Owner ID (KTP) document found" : "No Owner ID (KTP) document found"}</Text>
            </Stack>
        </Stack>
    }

    return <Panel headerText={"Set as Verified Vendor"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting && hasPermission ? (
                        <>
                            <PrimaryButton text={"Set as Verified"} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Verifying vendor ..."} /> : null}
            </Stack>;
        }}>
        <Stack>
            {!loaded ? <Stack horizontalAlign={"baseline"} styles={{ root: { marginTop: 20 } }}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && vendor && hasPermission ? <>
                    <Stack tokens={{ childrenGap: 20 }}>
                        <Text>Are you sure you want to set vendor with details below as verified?</Text>
                        <Stack>
                            <Label size={'xsmall'}>Name</Label>
                            <Text size={'small'}>{vendor.name}</Text>
                        </Stack>
                        <Stack>
                            <Label size={'xsmall'}>Address</Label>
                            <Text size={'small'}>{vendor.address || "-"}</Text>
                        </Stack>
                        <Stack>
                            <Label size={'xsmall'}>Phone number</Label>
                            <Text size={'small'}>{vendor.phoneNumber || "-"}</Text>
                        </Stack>
                        <Stack>
                            <Label size={'xsmall'}>NPWP</Label>
                            <Text size={'small'}>{vendor.npwp || "-"}</Text>
                        </Stack>
                        <Stack className={'divider'} />
                        {renderRelatedDocuments(vendor.documents || [])}
                        <Stack className={'divider'} />
                        <Stack>
                            <Label size={'xsmall'}>Owner name</Label>
                            <Text size={'small'}>{vendor.ownerName || "-"}</Text>
                        </Stack>
                        <Stack>
                            <Label size={'xsmall'}>Onwer ID number</Label>
                            <Text size={'small'}>{vendor.ownerIdentification || "-"}</Text>
                        </Stack>
                    </Stack>
                </> : null
            }
            {!hasPermission ? <NoAccess /> : null}
        </Stack>
    </Panel>
};

export default VerifyVendorForm;
