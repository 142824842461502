import React, { useEffect } from 'react';

// assets

// services

// props

// components
import { Dropdown, SpinnerSize, Stack } from '@fluentui/react';
import Label from '../../../typography/label';
import LoadingComponent from '../../../feedbacks/loading';
import { IDataServiceResourceShortProps } from '../../../../props/data/services';
import DataServiceService from '../../../../services/data/service';

type SelectServiceProps = {
    selected?: IDataServiceResourceShortProps;
    options?: IDataServiceResourceShortProps[];
    qs?: string[];
    label?: string;
    hideLabel?: boolean;
    required?: boolean;
    disabled?: boolean;
    allowCreate?: boolean;
    onChange(selected?: IDataServiceResourceShortProps): void;
}

const SelectService: React.FC<SelectServiceProps> = (props: SelectServiceProps) => {
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [selected, setSelected] = React.useState<IDataServiceResourceShortProps | undefined>();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<IDataServiceResourceShortProps[]>([]);

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    useEffect(() => {
    }, [props]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _services = await DataServiceService.retrieve();
        setOptions(_services);
        setLoaded(true);
    }

    return <Stack>
        {props.hideLabel ? null : <Label style={{ padding: '3px 0px' }} size={'xsmall'} required={props.required}>{props.label || "Bank account"}</Label>}
        <Stack tokens={{childrenGap: 3}}>
            <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign={'center'} styles={{root: {width: '100%'}}}>
                {!loaded ? <LoadingComponent label={""} size={SpinnerSize.small} /> : null}
                <Stack styles={{root: {width: '100%'}}}>
                    <Dropdown disabled={props.disabled || !loaded}
                        selectedKey={selected?.id}
                        options={options.map((s) => {
                            return { key: s.id, text: s.name }
                        })}
                        onChange={(evt, opt) => {
                            const selected = options.find((o) => o.id === opt?.key as string);
                            props.onChange(selected);
                        }} />
                </Stack>
            </Stack>
        </Stack>
    </Stack>;
};

export default SelectService;
