import React, { useEffect } from 'react';
import moment from 'moment';
import styles from './styles.module.scss';
import { useStore } from '../../../../../../stores/root';
import PaymentMethods from '../../../../../../manifests/paymentMethods';

// assets
import { faArrowRight, faCalendar, faCheck, faCopy, faFileInvoiceDollar, faHashtag, faInputNumeric, faMagnifyingGlassDollar, faRoute, faShip, faTag, faTrain, faTriangleExclamation, faTruck, faXmark, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';

// services
import GeneralService from '../../../../../../services/general';
import ErrorService from '../../../../../../services/general/error';
import OutcomePaymentService from '../../../../../../services/finance/outcomes/payments';
import PermissionsService from '../../../../../../services/permissions';

// props
import { IOutcomePaymentResourceShortProps } from '../../../../../../props/finance/outcomes/payments';
import { IPaginationResourceShort } from '../../../../../../props/general';
import { IOutcomeResourceShorterProps } from '../../../../../../props/finance/outcomes';

// components
import { ActionButton, CommandBar, DefaultButton, IColumn, Selection, ICommandBarItemProps, IContextualMenuItem, Link, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import Text from './../../../../../typography/text';
import Tag, { TTagVariant } from '../../../../../uiframeworks/tag';
import Label from '../../../../../typography/label';
import OutcomeInvoiceDetails from '../../../invoices/general/details';
import DownloadOutcomePaymentsReport from '../download/report';
import Pagination from '../../../../../uiframeworks/pagination';
import GlobalConfig from '../../../../../../config';
import DownloadTaxRecapOutcomePaymentsReport from '../taxRecap/report';
import OutcomePaymentsListFilterButton, { TFilterBy } from './filter';
import { FilterOptionProps } from '../../../../../uiframeworks/filters/panel';
import OutcomePaymentCompleteForm from '../form/complete';
import OutcomePaymentRejectForm from '../form/reject';
import PivotButton from '../../../../../uiframeworks/buttons/pivot';
import OutcomePaymentDetails from '../details';
import OutcomePaymentForm from '../form';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilesListColumn from '../../../../../uiframeworks/files/listcolumn';
import { faCircleCheck, faCircleXmark, faClock } from '@fortawesome/pro-solid-svg-icons';

interface IOutcomePaymentsListProps {
    qs?: string[];
    payments?: IOutcomePaymentResourceShortProps[];
    columns?: TOrderInvoicePaymentColumn[];
    hideSearch?: boolean;
    hideFilter?: boolean;
    hideCommandBar?: boolean;
    hideCreateButton?: boolean;
    hideDownloadReportButton?: boolean;
    hideDownloadTaxRecapButton?: boolean;
    hidePivotFilter?: boolean;
    variant?: 'card' | 'plain';
    filters?: TFilterBy[];
}

export type TOrderInvoicePaymentColumn = 'dueDate' | 'vendor' | 'subsidiary' | 'method' | 'paymentDate' | 'notes' | 'transferFrom' | 'transferTo' | 'details' | 'payment' | 'totalAmount' | 'actions' | 'status' | 'images' | 'proofOfPayments';

const OutcomePaymentsList: React.FC<IOutcomePaymentsListProps> = (props: IOutcomePaymentsListProps) => {
    const [shownColumns, setShownColumns] = React.useState<TOrderInvoicePaymentColumn[]>(props.columns || [
        'method',
        'vendor',
        'dueDate',
        'totalAmount',
        'notes',
        'actions',
        'details',
        'transferFrom',
        'transferTo',
        'subsidiary'
    ]);

    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [payments, setPayments] = React.useState<IOutcomePaymentResourceShortProps[]>(props.payments || []);
    const [pagination, setPagination] = React.useState<IPaginationResourceShort | undefined>();
    const [selectedType, setSelectedType] = React.useState<string | undefined>(props.hidePivotFilter ? undefined : 'vendor');
    const [selectedStatus, setSelectedStatus] = React.useState<string | undefined>(props.hidePivotFilter ? undefined : 'requested');
    const [selectedFilter, setSelectedFilter] = React.useState<FilterOptionProps[]>([]);
    const [keyword, setKeyword] = React.useState<string>("");

    const [activeInvoice, setActiveInvoice] = React.useState<IOutcomeResourceShorterProps | undefined>();
    const [selectedPayments, setSelectedPayments] = React.useState<IOutcomePaymentResourceShortProps[]>([]);
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();

    const hasPermission = PermissionsService.hasPermission(['outcomes.invoices.payments.read.all'], user.permissions);


    const _selection: Selection = new Selection({
        onSelectionChanged: () => {
            const selected = _selection.getSelection() as IOutcomePaymentResourceShortProps[];
            setSelectedPayments(selected);
        }
    });

    const columns: IColumn[] = [
        {
            key: "paymentDate",
            name: "Actioned on",
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: IOutcomePaymentResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    {item.paidOn ? <Link onClick={() => {
                        setSelectedPayments([item]);
                        setActiveSurface('details');
                    }}><Text size={'small'}>{moment(item.paidOn).format("DD/MM/YYYY")}</Text></Link> : <Text>-</Text>}
                </Stack>
            }
        },
        {
            key: "details",
            name: "Details",
            minWidth: 300,
            maxWidth: 300,
            isMultiline: true,
            onRender: (item: IOutcomePaymentResourceShortProps) => {
                const invoice = item.invoice;

                const warnings: { text: string; icon: IconProp }[] = [];
                if ((invoice?.invoiceNumber || "").trim() === "") {
                    warnings.push({ text: "No invoice number", icon: faInputNumeric });
                }
                if (invoice?.sys_duplicateInvoiceFound) {
                    warnings.push({ text: "Duplicate invoice number", icon: faCopy });
                }
                if ((invoice?.documents || []).findIndex((d) => (d.type || "").toLowerCase() === 'invoice') < 0) {
                    warnings.push({ text: "No invoice document", icon: faFileInvoiceDollar });
                }
                if (Number(invoice?.amount || "0") > Number(invoice?.unpaid || "0")) {
                    warnings.push({ text: "Amount missmatch", icon: faMagnifyingGlassDollar });
                }

                return <Stack styles={{ root: { padding: '4px 0px' } }} tokens={{ childrenGap: 15 }}>
                    <Stack tokens={{childrenGap: 5}}>
                        <Stack horizontal tokens={{childrenGap: 5}}>
                            <Stack styles={{root: {width: 100}}} horizontal horizontalAlign={'space-between'}>
                                <Text size={'small'}>Subsidiary</Text>
                                <Text size={'small'}>:</Text>
                            </Stack>
                            <Stack grow={1}><Label size={'xsmall'}>{item.invoice.subsidiary.name}</Label></Stack>
                        </Stack>
                        {item.invoice.vendor ? <Stack horizontal tokens={{childrenGap: 5}}>
                            <Stack styles={{root: {width: 100}}} horizontal horizontalAlign={'space-between'}>
                                <Text size={'small'}>Vendor</Text>
                                <Text size={'small'}>:</Text>
                            </Stack>
                            <Stack grow={1} horizontal tokens={{childrenGap: 3}}>
                                <Label size={'xsmall'}>{item.invoice.vendor.name}</Label>
                                {item.invoice.vendor.verificationStatus === 'pending_verification' ? <FontAwesomeIcon style={{marginTop: 1}} fontSize={14} className={'color-yellow'} icon={faClock as IconProp} /> : null}
                                {item.invoice.vendor.verificationStatus === 'verified' ? <FontAwesomeIcon style={{marginTop: 1}} fontSize={14} className={'color-green'} icon={faCircleCheck as IconProp} /> : null}
                                {item.invoice.vendor.verificationStatus === 'rejected' ? <FontAwesomeIcon style={{marginTop: 1}} fontSize={14} className={'color-red'} icon={faCircleXmark as IconProp} /> : null}
                            </Stack>
                        </Stack> : null}
                        {item.invoice.targetSubsidiary ? <Stack horizontal tokens={{childrenGap: 5}}>
                            <Stack styles={{root: {width: 100}}} horizontal horizontalAlign={'space-between'}>
                                <Text size={'small'}>Target subsidiary</Text>
                                <Text size={'small'}>:</Text>
                            </Stack>
                            <Stack grow={1}><Label size={'xsmall'}>{item.invoice.targetSubsidiary.name}</Label></Stack>
                        </Stack> : null}
                        {item.invoice.targetEmployee ? <Stack horizontal tokens={{childrenGap: 5}}>
                            <Stack styles={{root: {width: 100}}} horizontal horizontalAlign={'space-between'}>
                                <Text size={'small'}>Target employee</Text>
                                <Text size={'small'}>:</Text>
                            </Stack>
                            <Stack grow={1}><Label size={'xsmall'}>{item.invoice.targetEmployee.name}</Label></Stack>
                        </Stack> : null}
                    </Stack>
                    <Stack className={'divider'}> </Stack>
                    {invoice ? <>
                        <Link onClick={() => {
                            if (invoice) {
                                setActiveInvoice(invoice);
                                setActiveSurface('invoice.details');
                            }
                        }}>
                            <Tag>
                                <Stack tokens={{ childrenGap: 10 }} styles={{ root: { width: '100%' } }}>
                                    <Stack>
                                        <Label size={'xsmall'}>{invoice.name}</Label>
                                        <Text size={'small'}>(Rp. {GeneralService.getNumberWithSeparator(Number(item.amount || '0'))})</Text>
                                    </Stack>
                                    <Stack className={'dark-divider'}></Stack>
                                    <Stack tokens={{ childrenGap: 5 }}>
                                        <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 5 }}>
                                            <Stack styles={{ root: { width: 15 } }}><FontAwesomeIcon icon={faTag} fontSize={10} style={{ marginTop: -2 }} /></Stack>
                                            <Text size={'xsmall'}>{invoice.category.name}</Text>
                                        </Stack>
                                        {(invoice.invoiceNumber || "").trim() !== "" ? <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 5 }}>
                                            <Stack styles={{ root: { width: 15 } }}><FontAwesomeIcon icon={faHashtag} fontSize={10} style={{ marginTop: -2 }} /></Stack>
                                            <Text size={'xsmall'}>{invoice.invoiceNumber}</Text>
                                        </Stack> : null}
                                        {invoice.ship ? <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 5 }}>
                                            <Stack styles={{ root: { width: 15 } }}><FontAwesomeIcon icon={faShip} fontSize={10} style={{ marginTop: -2 }} /></Stack>
                                            <Text size={'xsmall'}>{invoice.ship.name || invoice.ship.registrationNumber}</Text>
                                        </Stack> : null}
                                        {invoice.shipSchedule ? <>
                                            <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 5 }}>
                                                <Stack styles={{ root: { width: 15 } }}><FontAwesomeIcon icon={faCalendar} fontSize={10} style={{ marginTop: -2 }} /></Stack>
                                                <Text size={'xsmall'}>Voy {invoice.shipSchedule.voy}</Text>
                                            </Stack>
                                            <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 5 }}>
                                                <Stack styles={{ root: { width: 15 } }}><FontAwesomeIcon icon={faRoute} fontSize={10} style={{ marginTop: -2 }} /></Stack>
                                                <Stack horizontal tokens={{ childrenGap: 5 }}>
                                                    <Text size={'xsmall'}>{invoice.shipSchedule.originAddress}</Text>
                                                    <FontAwesomeIcon icon={faArrowRight} fontSize={10} style={{ marginTop: 2 }} />
                                                    <Text size={'xsmall'}>{invoice.shipSchedule.destinationAddress}</Text>
                                                </Stack>
                                            </Stack>
                                        </> : null}
                                        {invoice.train ? <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 5 }}>
                                            <Stack styles={{ root: { width: 15 } }}><FontAwesomeIcon icon={faTrain} fontSize={10} style={{ marginTop: -2 }} /></Stack>
                                            <Text size={'xsmall'}>{invoice.train.name}</Text>
                                        </Stack> : null}
                                        {invoice.trainSchedule ? <>
                                            <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 5 }}>
                                                <Stack styles={{ root: { width: 15 } }}><FontAwesomeIcon icon={faCalendar} fontSize={10} style={{ marginTop: -2 }} /></Stack>
                                                <Text size={'xsmall'}>Voy {invoice.trainSchedule.voy}</Text>
                                            </Stack>
                                            <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 5 }}>
                                                <Stack styles={{ root: { width: 15 } }}><FontAwesomeIcon icon={faRoute} fontSize={10} style={{ marginTop: -2 }} /></Stack>
                                                <Stack horizontal tokens={{ childrenGap: 5 }}>
                                                    <Text size={'xsmall'}>{invoice.trainSchedule.originAddress}</Text>
                                                    <FontAwesomeIcon icon={faArrowRight} fontSize={10} style={{ marginTop: 2 }} />
                                                    <Text size={'xsmall'}>{invoice.trainSchedule.destinationAddress}</Text>
                                                </Stack>
                                            </Stack>
                                        </> : null}
                                        {invoice.truck ? <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 5 }}>
                                            <Stack styles={{ root: { width: 15 } }}><FontAwesomeIcon icon={faTruck} fontSize={10} style={{ marginTop: -2 }} /></Stack>
                                            <Text size={'xsmall'}>{invoice.truck.registrationNumber || invoice.truck.name}</Text>
                                        </Stack> : null}
                                    </Stack>
                                    {/*warnings.length > 0 ? <>
                                        <Stack className={'dark-divider'}></Stack>
                                        <Stack horizontal tokens={{ childrenGap: 10 }} wrap styles={{ root: { marginBottom: 5 } }}>
                                            {warnings.map((warning) => {
                                                return <Tag {...warning} variant={'error'} size={'small'} />
                                            })}
                                        </Stack>
                                    </> : null*/}
                                    {(invoice.errors || []).length > 0 || (invoice.warnings || []).length > 0 ? <>
                                        <Stack className={'dark-divider'}></Stack>
                                        <Stack tokens={{ childrenGap: 5 }}>
                                            {(invoice.errors || []).map((error) => {
                                                return <Stack horizontal tokens={{childrenGap: 5}}>
                                                    <Stack styles={{root: {width: 15, marginTop: 2}}}><FontAwesomeIcon icon={faXmark} fontSize={12} className={'color-red'} /></Stack>
                                                    <Text size={'xsmall'} className={'color-red'}>{error}</Text>
                                                </Stack>
                                            })}
                                            {(invoice.warnings || []).map((warning) => {
                                                return <Stack horizontal tokens={{childrenGap: 5}}>
                                                    <Stack styles={{root: {width: 15, marginTop: 1}}}><FontAwesomeIcon icon={faTriangleExclamation} fontSize={12} className={'color-yellow'} /></Stack>
                                                    <Text size={'xsmall'} className={'color-yellow'}>{warning}</Text>
                                                </Stack>
                                            })}
                                        </Stack>
                                    </> : null}
                                </Stack>
                            </Tag>
                        </Link>
                    </> : null}
                </Stack>
            }
        },
        {
            key: "actions",
            name: "",
            minWidth: 30,
            maxWidth: 30,
            onRender: (item: IOutcomePaymentResourceShortProps) => {
                const menuItems = [];

                if (item.status === 'requested') {
                    if (PermissionsService.hasPermission(['outcomes.invoices.payments.approve'], user.permissions)) {
                        menuItems.push({
                            key: "complete", text: "Complete", iconProps: { iconName: "Check" }, onClick: () => {
                                setSelectedPayments([item]);
                                setActiveSurface('complete');
                            }
                        });
                    }

                    if (PermissionsService.hasPermission(['outcomes.invoices.payments.reject'], user.permissions)) {
                        menuItems.push({
                            key: "reject", text: "Reject", iconProps: { iconName: "Xmark" }, onClick: () => {
                                setSelectedPayments([item]);
                                setActiveSurface('reject');
                            }
                        });
                    }
                }

                return <Stack.Item styles={{ root: { padding: '4px 0px' } }}>
                    {menuItems.length > 0 ? <Stack.Item className={"detailsListActionRow"}>
                        <ActionButton className={'detailsListActionButton'} menuProps={{
                            items: menuItems
                        }} />
                    </Stack.Item> : null}
                </Stack.Item>;
            }
        },
        {
            key: "transferFrom",
            name: "Transfer From",
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: IOutcomePaymentResourceShortProps) => {
                const method = PaymentMethods.find((pm) => pm.key === item.method)
                return <Stack styles={{ root: { padding: '4px 0px' } }} tokens={{ childrenGap: 5 }}>
                    {item.transferFrom ? <Stack tokens={{childrenGap: 3}}>
                        <Label size={'xsmall'}>{item.transferFrom.accountName}</Label>
                        <Text size={'small'}>{item.transferFrom.accountNumber} - {item.transferFrom.bank.name}</Text>
                    </Stack> : null}
                </Stack>
            }
        },
        {
            key: "transferTo",
            name: "Transfer To",
            minWidth: 225,
            maxWidth: 225,
            isMultiline: true,
            onRender: (item: IOutcomePaymentResourceShortProps) => {
                const method = PaymentMethods.find((pm) => pm.key === item.method)
                return <Stack styles={{ root: { padding: '4px 0px' } }} tokens={{ childrenGap: 5 }}>
                    <Label size={'xsmall'}>{method?.text || 'Cash'}</Label>
                    <Stack className={'divider'} />
                    {item.method === 'transfer' && item.transferTo ? <Stack tokens={{childrenGap: 3}}>
                        <Text size={'small'}>{item.transferTo.bank.name} ({item.transferTo.bankCity})</Text>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Link onClick={() => { 
                                window.navigator.clipboard.writeText(item.transferTo?.accountNumber || "");
                                banner.add({
                                    variant: 'success',
                                    text: `Account number ${item.transferTo?.accountNumber || ""} copied to clipboard`,
                                    icon: faCheck
                                });
                            }}>
                                <FontAwesomeIcon size={'sm'} icon={faCopy} />
                            </Link>
                            <Label size={'xsmall'}>{item.transferTo.accountNumber}</Label>
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign={'center'}>
                            <Link onClick={() => { 
                                window.navigator.clipboard.writeText(item.transferTo?.accountName || "");
                                banner.add({
                                    variant: 'success',
                                    text: `Account name ${item.transferTo?.accountName || ""} copied to clipboard`,
                                    icon: faCheck
                                });
                            }}>
                                <FontAwesomeIcon size={'sm'} icon={faCopy} />
                            </Link>
                            <Text size={'xsmall'}>(a/n {item.transferTo.accountName})</Text>
                        </Stack>
                    </Stack> : null}
                    {item.method === 'virtualaccount' && item.transferToVANumber ? <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign={'center'}>
                        <Link onClick={() => { 
                            window.navigator.clipboard.writeText(item.transferToVANumber || "");
                            banner.add({
                                variant: 'success',
                                text: `Virtual account number ${item.transferToVANumber || ""} copied to clipboard`,
                                icon: faCheck
                            });
                         }}>
                            <FontAwesomeIcon size={'sm'} icon={faCopy} />
                        </Link>
                        <Label size={'xsmall'}>{item.transferToVANumber || "-"}</Label>
                    </Stack> : null}
                </Stack>
            }
        },
        {
            key: "dueDate",
            name: "Due Date",
            minWidth: 75,
            maxWidth: 75,
            onRender: (item: IOutcomePaymentResourceShortProps) => {
                if (item.dueDate) {
                    const now = moment().startOf('day');
                    const target = moment(item.dueDate).startOf('day');
                    const diff = target.diff(now, 'day');

                    const tagText: string = GeneralService.getFriendlyDateFormat(item.dueDate);
                    let tagVariant: TTagVariant = 'success';
                    if (diff < 1) {
                        tagVariant = 'error';
                    } else if (diff > 2) {
                        tagVariant = 'success';
                    } else {
                        tagVariant = 'warning';
                    }

                    return <Stack horizontal styles={{ root: { padding: '4px 0px' } }}>
                        <Tag size={'small'} variant={tagVariant} text={tagText} />
                    </Stack>
                } else {
                    return <Stack styles={{ root: { padding: '4px 0px' } }}>
                        <Text size={'small'}>-</Text>
                    </Stack>
                }
            }
        },
        {
            key: "status",
            name: "Status",
            minWidth: 75,
            maxWidth: 75,
            onRender: (item: IOutcomePaymentResourceShortProps) => {
                let tagText: string = 'Requested';
                let tagVariant: TTagVariant = 'active';
                if (item.status === 'completed') {
                    tagText = 'Paid';
                    tagVariant = 'success';
                } else if (item.status === 'rejected') {
                    tagText = 'Rejected';
                    tagVariant = 'error';
                }

                return <Stack horizontal styles={{ root: { padding: '0px' } }}>
                    <Tag size={'small'} variant={tagVariant} text={tagText} />
                </Stack>
            }
        },
        {
            key: "notes",
            name: "Notes",
            minWidth: 100,
            isMultiline: true,
            onRender: (item: IOutcomePaymentResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text size={'small'}>{item.notes || "-"}</Text>
                </Stack>
            }
        },
        {
            key: "totalAmount",
            name: "Total Amount",
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: IOutcomePaymentResourceShortProps) => {
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Text size={'small'}>Rp. {GeneralService.getNumberWithSeparator(Number(item.totalAmount || '0'))}</Text>
                </Stack>
            }
        },
        {
            key: "images",
            name: "",
            minWidth: 75,
            maxWidth: 75,
            onRender: (item: IOutcomePaymentResourceShortProps) => {
                /*const images = item.invoice.documents?.filter(f => /\.(png|jpg|jpeg|gif)$/i.test(f.path))
                return <Stack styles={{ root: { padding: '4px 0px' } }}>
                    <Stack horizontal tokens={{ childrenGap: 10 }} wrap>
                        {images?.map(img => <RenderImage style={{ maxWidth: 50 }} key={img.id} path={img.path} />)}
                    </Stack>
                </Stack>*/
                return;
            }
        },
        {
            key: "proofOfPayments",
            name: "Payment Proof",
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onRender: (item: IOutcomePaymentResourceShortProps) => {
                return <FilesListColumn files={item.completionDocuments || []} size={'medium'} loaded={true} />
            }
        },
    ];

    useEffect(() => {
        if (!props.payments) {
            _onRetrievePayments();
        } else {
            setLoaded(true);
        }
    }, [selectedStatus, keyword]);

    const _onRetrievePayments = async (pageNumber?: number,) => {
        try {
            setLoaded(false);
            const qs: string[] = props.qs || [];
            qs.push(`top=${GlobalConfig.defaultTop}`);
            if (selectedStatus) { qs.push(`status=${selectedStatus}`); }
            if (pageNumber) { qs.push(`page=${pageNumber}`); }
            if (keyword && keyword.trim() !== "") { qs.push(`search=${keyword}`) }
            const result = await OutcomePaymentService.retrieve(qs.join("&"));
            setPayments([...result.data]);
            setPagination(result.pagination);
            setLoaded(true);
        } catch (e) {
            banner.add({
                key: 'retrieve_outcome_payments_list_error',
                text: 'Failed to retrieve outcome payments list. Error: ' + ErrorService.getMessage(e),
                icon: faXmarkCircle,
                variant: 'error'
            });
        }
    }

    const _onKeywordChanged = (value?: string) => {
        setKeyword(value || "");
    }

    const getCommandBarItems = () => {
        let items: ICommandBarItemProps[] = [];
        let farItems: ICommandBarItemProps[] = [];

        if (!props.hideSearch) {
            items.push({
                key: "search",
                onRender: () => {
                    return <SearchBox placeholder={"Search ..."} onSearch={_onKeywordChanged} styles={{ root: { marginRight: 10 } }} />
                }
            });
        }

        /*if (!props.hideFilter) {
            items.push({
                key: "filter",
                onRender: () => {
                    return <Stack styles={{ root: { marginRight: 10 } }}>
                        <OutcomePaymentsListFilterButton selected={selectedFilter}
                            filters={props.filters}
                            onChange={(selected) => {
                                setSelectedFilter([...selected]);
                            }} />
                    </Stack>
                }
            });
        }*/

        if (!props.hideCreateButton) {
            farItems.push({
                key: "register",
                text: "Request Payment",
                iconProps: { iconName: "Add" },
                onRender: () => {
                    return <PrimaryButton text={"Request Payment"}
                        iconProps={{ iconName: "Add" }}
                        onClick={() => { setActiveSurface('payments.create') }} />;
                }
            });
        }

        /*if (!props.hideDownloadReportButton || !props.hideDownloadTaxRecapButton) {
            items.push({
                key: "downloadReport",
                text: "Download Report",
                iconProps: { iconName: "Download" },
                onRender: () => {
                    const items: IContextualMenuItem[] = [];
                    if (!props.hideDownloadReportButton) {
                        items.push({
                            key: 'downloadReport',
                            text: "Report",
                            onClick: () => {
                                setActiveSurface('report.download');
                            }
                        });
                    }

                    if (!props.hideDownloadTaxRecapButton) {
                        items.push({
                            key: 'downloadTaxRecap',
                            text: "Tax Recap",
                            onClick: () => {
                                setActiveSurface('taxrecap.download');
                            }
                        });
                    }

                    return <DefaultButton text={"Download"}
                        iconProps={{ iconName: "Download" }}
                        menuProps={{ items }} />;
                }
            });
        }*/

        if (PermissionsService.hasPermission(['outcomes.invoices.payments.approve'], user.permissions) && selectedPayments.length > 0) {
            const sameTarget = !(selectedPayments.findIndex((payment) => {
                return payment.method !== selectedPayments[0].method ||
                    payment.invoice.vendor?.id !== selectedPayments[0].invoice.vendor?.id ||
                    payment.targetBankAccount?.id !== selectedPayments[0].targetBankAccount?.id ||
                    payment.targetVirtualAccountNumber !== selectedPayments[0].targetVirtualAccountNumber;
            }) > -1);

            items.push({
                key: "complete",
                onRender: () => {
                    return <DefaultButton text={"Complete"}
                        iconProps={{ iconName: "Check" }}
                        disabled={!sameTarget}
                        styles={{ root: { marginRight: 10 } }}
                        onClick={() => {
                            setActiveSurface('complete');
                        }} />
                }
            });
        }

        if (PermissionsService.hasPermission(['outcomes.invoices.payments.reject'], user.permissions) && selectedPayments.length > 0) {
            items.push({
                key: "reject",
                onRender: () => {
                    return <DefaultButton text={"Reject"}
                        styles={{ root: { marginRight: 10 } }}
                        iconProps={{ iconName: "XMark" }}
                        onClick={() => {
                            setActiveSurface('reject');
                        }} />
                }
            });
        }

        return { items, farItems };
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
        setSelectedPayments([]);

        if (refresh) { _onRetrievePayments() }
    }

    return <Stack className={styles.container} tokens={{ childrenGap: 20 }}>
        {hasPermission ? <>
            {!props.hideCommandBar ? <CommandBar
                items={getCommandBarItems().items}
                farItems={getCommandBarItems().farItems}
                styles={{
                    root: {
                        padding: 0,
                        height: 'unset',
                        backgroundColor: 'transparent'
                    }
                }}
                ariaLabel="Use left and right arrow keys to navigate between commands" /> : null}
            <Stack tokens={{ childrenGap: 10 }}>
                {pagination && payments.length > 0 ? <Pagination goToPage={_onRetrievePayments} currentPage={pagination.currentPage} totalPage={pagination.lastPage} text={pagination.total + " payment(s) found"} /> : null}
                {!props.hidePivotFilter ? <Stack tokens={{ childrenGap: 20 }} horizontal wrap>
                    <PivotButton buttons={[
                        {
                            key: "vendor",
                            text: "Vendor Payments",
                            active: selectedType === 'vendor',
                            onClick: () => {
                                setSelectedType('vendor');
                            }
                        },
                        /*{
                            key: "salary",
                            text: "Salary",
                            active: selectedType === 'salary',
                            onClick: () => {
                                setSelectedType('salary');
                            }
                        }*/
                    ]} />
                    <PivotButton buttons={[
                        {
                            key: "requested",
                            text: "Requested",
                            active: selectedStatus === 'requested',
                            onClick: () => {
                                if (!props.columns) {
                                    setShownColumns([
                                        'method',
                                        'vendor',
                                        'dueDate',
                                        'totalAmount',
                                        'notes',
                                        'actions',
                                        'details',
                                        'payment'
                                    ])
                                }
                                setSelectedStatus('requested');
                            }
                        },
                        {
                            key: "completed",
                            text: "Completed",
                            active: selectedStatus === 'completed',
                            onClick: () => {
                                if (!props.columns) {
                                    setShownColumns([
                                        'method',
                                        'paymentDate',
                                        'totalAmount',
                                        'notes',
                                        'details',
                                        'payment'
                                    ])
                                }
                                setSelectedStatus('completed');
                            }
                        },
                        {
                            key: "rejected",
                            text: "Rejected",
                            active: selectedStatus === 'rejected',
                            onClick: () => {
                                if (!props.columns) {
                                    setShownColumns([
                                        'method',
                                        'paymentDate',
                                        'totalAmount',
                                        'notes',
                                        'details',
                                        'payment'
                                    ])
                                }
                                setSelectedStatus('rejected');
                            }
                        }
                    ]} />
                </Stack> : null}
                <Stack className={styles[props.variant || 'card']}>
                    {
                        !loaded || (loaded && payments.length > 0) ? (
                            <>
                                <ShimmeredDetailsList
                                    selection={_selection}
                                    setKey="items"
                                    items={payments}
                                    columns={columns.filter((col) => shownColumns.indexOf(col.key as TOrderInvoicePaymentColumn) > -1)}
                                    selectionMode={props.hideCommandBar ? SelectionMode.none : SelectionMode.multiple}
                                    enableShimmer={!loaded}
                                    onShouldVirtualize={() => false}
                                    ariaLabelForShimmer="Content is being fetched"
                                    ariaLabelForGrid="Item details" />
                            </>
                        ) : null
                    }
                    {loaded && payments.length < 1 ? <Stack styles={{ root: { padding: props.variant === 'plain' ? 0 : 10 } }}>
                        <Text size={'small'} style={{ fontStyle: 'italic' }}>Payments not found</Text>
                    </Stack> : null}
                </Stack>
                {pagination && payments.length > 0 ? <Pagination goToPage={_onRetrievePayments} currentPage={pagination.currentPage} totalPage={pagination.lastPage} /> : null}
            </Stack>
            {activeSurface === 'details' && selectedPayments[0] ? <OutcomePaymentDetails paymentId={selectedPayments[0].id} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'complete' && selectedPayments.length > 0 ? <OutcomePaymentCompleteForm payments={selectedPayments} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'reject' && selectedPayments.length > 0 ? <OutcomePaymentRejectForm payments={selectedPayments} onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'report.download' ? <DownloadOutcomePaymentsReport onDismissed={_onSurfaceDismissed} /> : null}
            {activeSurface === 'taxrecap.download' ? <DownloadTaxRecapOutcomePaymentsReport onDismissed={_onSurfaceDismissed} /> : null}

            {activeSurface === 'payments.create' ? <OutcomePaymentForm onDismissed={_onSurfaceDismissed} /> : null}

            {activeSurface === 'invoice.details' && activeInvoice ? <OutcomeInvoiceDetails outcomeId={activeInvoice.id} onDismissed={_onSurfaceDismissed} /> : null}
        </> : null}
    </Stack>;
};

export default OutcomePaymentsList;
