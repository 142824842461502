import React, { useEffect } from 'react';
import { useStore } from '../../../../../../stores/root';

// assets
import { faClose } from '@fortawesome/pro-light-svg-icons';

// services
import VendorsService from '../../../../../../services/data/vendors';

// props

// components
import { ActionButton, DefaultButton, DetailsList, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import PermissionsService from '../../../../../../services/permissions';
import ErrorService from '../../../../../../services/general/error';
import NoAccess from '../../../../../uiframeworks/noAccess';
import { IVendorResourceProps } from '../../../../../../props/data/vendors';
import Label from '../../../../../typography/label';
import VendorField from '../../../../../uiframeworks/forms/vendor';
import { IVendorServiceResourceShortProps } from '../../../../../../props/data/vendors/service';
import VendorServiceItemForm from './item';

type VendorServicesFormProps = {
    vendorId: string;
    onDismissed(refresh?: boolean, vendor?: IVendorResourceProps): void;
}

const VendorServicesForm: React.FC<VendorServicesFormProps> = (props: VendorServicesFormProps) => {
    const { banner, user } = useStore();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [vendor, setVendor] = React.useState<IVendorResourceProps | undefined>();
    const [services, setServices] = React.useState<IVendorServiceResourceShortProps[]>([]);
    const [activeSurface, setActiveSurface] = React.useState<string | undefined>();
    const [error, setError] = React.useState<any>({});

    let hasPermission = PermissionsService.hasPermission(['vendors.update.all'], user.permissions);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _vendor = await VendorsService.get(props.vendorId);

        setVendor(_vendor);
        setLoaded(true);
    }

    const _onSubmit = async () => {
        try {
            setSubmitting(true);
        } catch (e) {
            banner.add({
                key: 'update_vendor_services_error',
                variant: 'error',
                icon: faClose,
                text: ErrorService.getMessage(e)
            });
            setSubmitting(false);
        }
    }

    const _onSurfaceDismissed = (refresh?: boolean) => {
        setActiveSurface(undefined);
    }

    return <Panel headerText={"Update Vendor Services"}
        isOpen={true}
        type={PanelType.medium}
        onDismiss={() => props.onDismissed(false)}
        isFooterAtBottom={true}
        onRenderFooterContent={() => {
            return <Stack horizontal tokens={{ childrenGap: 10 }}>
                {
                    !submitting && hasPermission ? (
                        <>
                            <PrimaryButton text={"Submit"} disabled={services.length > 0} onClick={_onSubmit} />
                            <DefaultButton text={"Cancel"} onClick={() => { props.onDismissed(false) }} />
                        </>
                    ) : null
                }
                {submitting ? <Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Updating vendor services ..."} /> : null}
            </Stack>;
        }}>
        <Stack>
            {!loaded ? <Stack horizontalAlign={"baseline"} styles={{ root: { marginTop: 20 } }}><Spinner size={SpinnerSize.medium} labelPosition={"right"} label={"Preparing form ..."} /></Stack> : null}
            {
                loaded && vendor && hasPermission ? <>
                    <Stack tokens={{ childrenGap: 20 }}>
                        <VendorField label={'Vendor'}
                            selected={vendor}
                            disabled={true}
                            onChange={() => {}} />
                        <Stack className={'divider'}></Stack>
                        <Stack horizontal tokens={{childrenGap: 20}} verticalAlign={'center'} horizontalAlign={'space-between'}>
                            <Label size={'xsmall'}>Available Services / Areas</Label>
                            <ActionButton iconProps={{iconName: "Add"}} text={'Add'} onClick={() => setActiveSurface('services.add')} />
                        </Stack>
                    </Stack>
                </> : null
            }
            {!hasPermission ? <NoAccess /> : null}
            {activeSurface === 'services.add' ? <VendorServiceItemForm onDismissed={_onSurfaceDismissed} /> : null}
        </Stack>
    </Panel>
};

export default VendorServicesForm;
